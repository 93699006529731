
import { EditOutlined, FileTextOutlined } from '@ant-design/icons';
import { Input, Radio } from "antd";
import { FastField, FieldProps } from "formik";
import * as _ from "lodash";
import React, { useRef, useState } from "react";
import ReactJson from 'react-json-view';
import { FieldType } from "../../common/schema";
import { onBlur, onKeyDown } from './common';
import { EditFieldSpec, FieldSpec, ViewFieldSpec } from "./types";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";

const JSONEditView = ({ fieldName, fieldType, fp }: { fp: FieldProps, fieldName: string, fieldType: FieldType }) => {
    let json;
    const record = fp.field.value;
    let changeFun = undefined
    if (fieldType === FieldType.STRING) {
        changeFun = (e: any) => { fp.form.setFieldValue(fieldName, JSON.stringify(e.updated_src, undefined, 2)) }
        if (typeof record === 'string' && record.length > 0) {
            try {
                json = JSON.parse(record)
            } catch (e) {
                // errors means empty json
                json = JSON.parse("{}")
            }
        }
    }

    if (fieldType === FieldType.OBJECT) {
        json = record
        changeFun = (e: any) => { fp.form.setFieldValue(fieldName, e.updated_src) }
    }

    return <ReactJson src={json} name={false} onEdit={changeFun} onAdd={changeFun} onDelete={changeFun} />
};

const JSON_String = ({ fieldConfig, fieldName, field, fp }: EditFieldSpec & { fp: FieldProps }) => {

    const [jsonView, setJsonView] = useState("jeditv")

    return <div style={{ width: "100%" }}>
        <div style={{ marginBottom: "5px" }}>
            <Radio.Group defaultValue={jsonView} size="small" onChange={(e) => setJsonView(e.target.value)}>
                <Radio.Button value="jeditv"><EditOutlined /> Json</Radio.Button>
                <Radio.Button value="ace"><FileTextOutlined /> Editor</Radio.Button>
            </Radio.Group>
        </div>
        <div className="code-editor">
            {jsonView == "jeditv" ? <JSONEditView fp={fp} fieldName={fieldName} fieldType={field.type} ></JSONEditView> : null}
            {jsonView == "ace" ? <AceEditor mode="json" theme="github" defaultValue={fp.field.value}
                width={fieldConfig!.codeConfig?.width || '100%'} height={fieldConfig!.codeConfig?.height || "200px"}
                onBlur={onBlur(fp.form, fp.field.onBlur)}
                onFocus={() => fp.form.setFieldTouched(fieldName, true, false)}
                debounceChangePeriod={400} onChange={(value) => { fp.form.setFieldValue(fieldName, value) }} /> : null}
        </div>

    </div>

}

const TextArea = ({ fieldConfig, fieldName, fp }: EditFieldSpec & { fp: FieldProps }) => {
    const { field, form, ...props } = fp;
    const { value, ...propsNotValue } = field;
    const debouncedHandleChange = useRef(_.debounce(function (e) { form.setFieldValue(fieldName, e.value) }, 400));

    return <Input.TextArea {...propsNotValue} {...props} autoSize={{ minRows: 3, maxRows: 5 }} {...fieldConfig!.textAreaConfig}
        defaultValue={field.value}
        onBlur={onBlur(form, field.onBlur)}
        onKeyDown={onKeyDown(form)}
        onChange={(e) => { debouncedHandleChange.current(e.target) }} />;
}


export const SimpleStringField = ({ fp }: { fp: FieldProps }) => {
    const { field, form, ...props } = fp;
    const { value, ...propsNotValue } = field;
    const debouncedHandleChange = useRef(_.debounce(function (e) { form.setFieldValue(e.name, e.value) }, 400));
    return <Input {...propsNotValue} {...props} defaultValue={field.value}
        onBlur={onBlur(form, field.onBlur)}
        onKeyDown={onKeyDown(form)}
        onChange={(e) => { debouncedHandleChange.current(e.target) }} />;
}

export const CodeStringField = ({ fieldConfig, fieldName, fp }: EditFieldSpec & { fp: FieldProps }) => {
    return <div className="code-editor">
        <AceEditor mode={fieldConfig!.codeConfig!.mode} theme="github" defaultValue={fp.field.value}
            width={fieldConfig!.codeConfig?.width || '100%'} height={fieldConfig!.codeConfig?.height || "200px"}
            onFocus={() => fp.form.setFieldTouched(fp.field.name, true, false)}
            onBlur={onBlur(fp.form, fp.field.onBlur)}
            debounceChangePeriod={400} onChange={(value) => { fp.form.setFieldValue(fieldName, value) }} />
    </div>
}


export const EditFieldComponent = (props: EditFieldSpec) => {
    const { fieldConfig, fieldName } = props

    let Component: any = SimpleStringField;
    if (fieldConfig && fieldConfig.displayType === "JSON") {
        Component = JSON_String;
    }

    if (fieldConfig && fieldConfig.displayType === "TEXTAREA") {
        Component = TextArea
    }

    if (fieldConfig && fieldConfig.displayType === "CODE") {
        Component = CodeStringField
    }

    return <FastField name={fieldName} children={(fp: FieldProps) => <Component {...props} fp={fp} />} />

}

export const ViewFieldComponent = (props: ViewFieldSpec) => {
    const { value, fieldConfig } = props

    if (fieldConfig && fieldConfig.displayType === "JSON") {
        let json;
        if (typeof value === 'string') {
            try {
                json = JSON.parse(value)
            } catch (e) {
                // TODO what to do during JSON errors ?
            }
        }

        if (typeof value === 'object')
            json = value

        return <ReactJson src={json} name={false} />
    }

    if (fieldConfig && fieldConfig.displayType === "CODE") {
        return <AceEditor mode={fieldConfig.codeConfig!.mode} theme="github" readOnly={true} defaultValue={value}
            width={fieldConfig.codeConfig?.width || '100%'} height={fieldConfig.codeConfig?.height || "200px"} />
    }

    let st: String = "";
    if (typeof value === 'string')
        st = value;
    if (value != null)
        st = new String(value);
    return <span style={{ whiteSpace: "pre-wrap" }}>{st}</span>;
}


export default { EditFieldComponent, ViewFieldComponent } as FieldSpec