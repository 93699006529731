import { Badge, Switch } from "antd";
import { FastField, FieldProps } from "formik";
import React from "react";
import { EditFieldSpec, FieldSpec, ViewFieldSpec } from "./types";

export const EditFieldComponent = (props: EditFieldSpec) => {
    const { fieldName } = props
    return <FastField name={fieldName}>
        {({ field, form, ...props }: FieldProps) => {
            const { value, ...propsNotValue } = field;
            return <Switch {...propsNotValue} {...props} defaultChecked={field.value} onChange={(checked) => { form.setFieldValue(field.name, checked) }} />
        }}
    </FastField>
}

export const ViewFieldComponent = (props: ViewFieldSpec) => {
    const { value } = props
    if (typeof value == 'boolean') {
        // TODO true label and false label
        return value ? <Badge status="success" text="True" /> : <Badge status="error" text="False" />
    }
    return <Badge status="error" text="False" />
}


export default { EditFieldComponent, ViewFieldComponent } as FieldSpec