import { GeminiSchema } from "./schema";

export function entityDisplayName(schema: GeminiSchema) {
    return schema.displayName || schema.name;
}

export function convertRecordsToTree(records: any, idField: string = 'id', parentField: string = 'parent', childrenAttr: string = 'children') {
    const treeList: any[] = [];
    const lookup = {};

    const copy = JSON.parse(JSON.stringify(records));

    copy.forEach((obj: any) => {
        lookup[obj[idField]] = obj; // need a copy here
    });
    copy.forEach((obj: any) => {
        if (obj[parentField] != null && lookup[obj[parentField]]) {
            if (!lookup[obj[parentField]][childrenAttr])
                lookup[obj[parentField]][childrenAttr] = []
            lookup[obj[parentField]][childrenAttr].push(obj);
        } else {
            treeList.push(obj);
        }
    });
    return treeList;
};


export function nestedTreeSort(records: any[], sortField: string = 'sortkey', childrenAttr: string = 'children') {
    records.sort((a, b) => a[sortField] - b[sortField])
    records.forEach(r => {
        if (r[childrenAttr] && Array.isArray(r[childrenAttr]) && r[childrenAttr].length > 0)
            nestedTreeSort(r[childrenAttr])
    })
    return records;
}