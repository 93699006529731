import { Field } from "../common/schema";
import { EntityRecordViewConfig } from "../common/types";


/**
 * Returns the configuration path from a full fieldName Path (ex: arrayField.0.innerBoolean => arrayField.innerBoolean).
 * @param fullFieldName 
 */
export function getConfigPath(fullFieldName: string){
    return fullFieldName.split(".").filter(a => isNaN(parseInt(a))).join(".")
}

export function fieldSorter(path: string, fields: Field[], config?: EntityRecordViewConfig) {
    return function (a: Field, b: Field): number {
        // we want sort by configuration or by lk or by name -- it is always deterministic
        let aPath = path == "" ? a.name : path + "." + a.name;
        let bPath = path == "" ? b.name : path + "." + b.name;

        // init equals sortering order
        let aval = 99999;
        let bval = 99999;

        // use a configuration order
        if (config && config.shortcuts && config.shortcuts.ordering) {
            let c_aval = config.shortcuts.ordering.indexOf(aPath)
            let c_bval = config.shortcuts.ordering.indexOf(bPath)
            aval = c_aval == -1 ? aval : c_aval;
            bval = c_bval == -1 ? bval : c_bval;
        }

        // no configuration found
        if (aval === bval) {
            const aIndex = fields.findIndex(f => f.name === a.name);
            const bIndex = fields.findIndex(f => f.name === b.name);

            if (aIndex >= 0 && bIndex >= 0)
                return aIndex - bIndex;
        }

        // if no ordering wins -- lets use the pathName
        const finalOrder = aval - bval;
        if (finalOrder === 0) {
            return aPath.localeCompare(bPath);
        }
        return finalOrder;
    }
}