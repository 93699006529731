import { Field, FieldType } from "components/common/schema";
import { EntityTableViewConfig } from "components/common/types";

export const treeFieldSettingsFn = (fields: Field[], path: string, config?: EntityTableViewConfig): any[] => {
    if (!fields)
        return []


    return fields.map(f => {

        if ([FieldType.DICTIONARY, FieldType.ARRAY, FieldType.B64_IMAGE].includes(f.type))
            return undefined;

        const fieldPath = path == "" ? f.name : path + "." + f.name


        if (config?.enabledFields) {
            if (!config.enabledFields.includes(path) && !config.enabledFields.includes(fieldPath) && config.enabledFields.findIndex(s => s.startsWith(fieldPath + ".")) < 0) {
                return undefined;
            }
        }

        let rv: any = {
            title: f.displayName ?? f.name,
            key: fieldPath,
        }
        if (f.type === FieldType.OBJECT) {
            rv.children = treeFieldSettingsFn(f.object!.fields, fieldPath, config)
        }

        return rv;
    }).filter(f => f != undefined);
}

export const checkedFields = (arr: any[], filterSet?: string[]) => {

    const filter = (key: string) => (filterSet && filterSet.includes(key)) || !filterSet;
    return arr.reduce(
        (acc: any[], item: { key: string, children: any[] }) => {

            if (!item)
                return acc;

            const newItem = item;

            if (item.children && item.children.length > 0) {
                acc = acc.concat(checkedFields(item.children, filterSet))
            }

            if (filter(item.key))
                acc.push(newItem.key);
            return acc;
        },
        []
    )
};

export const getActiveFields = (enabledFields: any[], checkedFields: string[], allChecked?: boolean) => {

    return enabledFields.reduce(
        (acc: any[], item: { key: string, children: any[] }) => {

            if (!item)
                return acc;

            const inStrictlyIncluded = checkedFields.includes(item.key);
            let activeChilds = [];
            if (item.children && item.children.length > 0) {
                activeChilds = getActiveFields(item.children, checkedFields, allChecked || inStrictlyIncluded);
                acc = acc.concat(activeChilds)
            }

            if (allChecked || inStrictlyIncluded || activeChilds.length > 0)
                acc.push(item.key);
            return acc;
        },
        []
    )
}

export const allCheckedFn = (enabledFields: any[], activeFields: string[]): boolean => {
    return enabledFields.reduce((acc, field: {key: string, children: any[]}) => {
        if(!activeFields.includes(field.key))
            return false
        if (field.children)
            return acc && allCheckedFn(field.children, activeFields)
        return acc 
    }, true)
}