import * as _ from "lodash";
import { Dispatch, SetStateAction } from "react";
import { Field, GeminiSchema } from "./schema";
import { EntityRecordViewConfig, EntityReferenceConfigResolver, EntityReferenceRecordResolver, EntityViewConfig } from "./types";

export function getRecordLk(record: any, schema: GeminiSchema): string {
    if (schema.singleRecord) {
        return schema.lkValue || "_singlerec_lk";
    }
    let mergeSt = schema.lkSeparator || ""
    let res = "";
    for (let lkpart of schema.lk) {

        let elems = lkpart.split(".");
        let finalFieldVal;
        for (let elem of elems) {
            if (record.hasOwnProperty(elem))
                finalFieldVal = record[elem]
        }
        if (finalFieldVal)
            res += res === '' ? finalFieldVal : mergeSt + finalFieldVal;
    }
    return res;
}

export function activeField(record: any, fieldName: string, config?: EntityRecordViewConfig) {
    if (config && config.fields) {
        const fieldConfig = config.fields[fieldName];
        if (fieldConfig && fieldConfig.activeIf && typeof fieldConfig.activeIf === 'string') {
            const fun = new Function("rec", 'return ' + fieldConfig.activeIf);
            return fun(record);
        }
    }
    return true;
}


export function getRecordReferenceDisplayValue(
    field: Field, value: any,
    setConfiguration: Dispatch<SetStateAction<{ loaded: boolean, schema?: GeminiSchema, config?: EntityViewConfig, value?: any }>>,
    entityReferenceConfigResolver?: EntityReferenceConfigResolver,
    entityReferenceRecordResolver?: EntityReferenceRecordResolver,
    ) {
    if (entityReferenceConfigResolver) {
        entityReferenceConfigResolver(field.entityRef!.entity).then(r => {
            let fields = r.schema?.refDisplayFields;
            const separator = r.schema?.refDisplaySeparator ?? " - ";
            if (!fields) {
                setConfiguration({ loaded: true, value: value, ...r })
            } else {
                if (entityReferenceRecordResolver) {
                    entityReferenceRecordResolver(field.entityRef!.entity, value)
                        .then(rec => {

                            const values = _.at(rec, fields!);
                            setConfiguration({ loaded: true, ...r, value: values.join(separator) })
                        })
                }
            }

        })
    } else {
        setConfiguration({ loaded: true, value: value })
    }
}