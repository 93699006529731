
export interface GeminiSchema {
    name: string
    displayName?: string
    lk: string[]
    lkSeparator?: string
    editable?: boolean
    fields: Field[]
    singleRecord?: boolean
    lkValue?: string
    tree?: {
        enabled: boolean
        parentField: string
    }
    refDisplayFields?: string[]
    refDisplaySeparator?: string
    getStrategies?: GetStartegies[]
}

export interface Field {
    name: string
    displayName?: string
    required?: boolean
    type: FieldType
    object?: {fields: Field[]}
    dict?: {fields: Field[]}
    enums?: string[]
    array?: ArrayType
    select?: SelectType
    any?: AnyType
    entityRef?: EntityRef
    geoHashLocation?: GeoHashLocation
}

export interface ArrayType {
    type: FieldType
    object?: {fields: Field[]}
    enums?: string[]
    select?: SelectType
    array?: ArrayType
    entityRef?: EntityRef
}

export interface SelectType {
    elems: SelectElem[]
}

export interface SelectElem {
    value: string
    displayName: string
}

export interface AnyType {
    typeField: string
}

export interface EntityRef {
    entity: string
}

export interface GeoHashLocation {
    includeGooglePlaceId: true
}

export enum FieldType {
    STRING = 'STRING',
    ENUM = 'ENUM',
    BOOL = 'BOOL',
    OBJECT = 'OBJECT',
    INTEGER = 'INTEGER',
    DOUBLE = 'DOUBLE',
    DATE = 'DATE',
    DICTIONARY = 'DICTIONARY',
    ARRAY = 'ARRAY',
    SELECT = 'SELECT',
    B64_IMAGE = 'B64_IMAGE',
    ANY = 'ANY',
    ENTITY_REF = 'ENTITY_REF',
    GEOHASH_LOCATION = 'GEOHASH_LOCATION'
}

export enum GetStartegies {
    ALL = 'ALL'
}