import { EntityTableViewConfig, EntityViewConfig, EntityViewProp, GlobalLocale } from "../common/types";
import * as React from 'react';
import { Badge, Spin } from "antd";
import {
    ReloadOutlined,
    SyncOutlined
} from '@ant-design/icons';
import { Field } from "components/common/schema";

export function recordStatusHeader(status: EntityViewProp["recordsStatus"], visibleStatus: EntityViewProp["visibleStatus"], oldStatusConfig: EntityViewProp["oldStatusConfig"], locale: GlobalLocale) {
    if (!visibleStatus)
        visibleStatus = ['CHECKED', 'CHECKING', 'OLD']
    if (status && visibleStatus.includes(status)) {
        switch (status) {
            case "CHECKED":
                return <span style={{ marginLeft: "6px" }}><Badge status="success" />{locale.recordsStatus.updated}</span>
            case "CHECKING":
                return <span style={{ marginLeft: "6px" }}><Badge status="processing" />{locale.recordsStatus.updating}</span>
            case "OLD":
                if (oldStatusConfig) {
                    const rtf = new Intl.RelativeTimeFormat(locale.code, {
                        localeMatcher: "best fit", // other values: "lookup"
                        numeric: "always", // other values: "auto"
                        style: "long", // other values: "short" or "narrow"
                    });
                    return <span style={{ marginLeft: "6px" }}><Badge status="warning" />{locale.recordsStatus.lastCheck + rtf.format(oldStatusConfig.value, oldStatusConfig.unit)} </span>
                }
        }
    }
    return undefined;
}

export function reloadButton(status: EntityViewProp["recordsStatus"], reloadFunction?: () => void) {

    if (reloadFunction) {
        switch (status) {
            case "CHECKED":
            case "OLD":
                return <span style={{ marginLeft: "10px", color: "black", cursor: "pointer" }}><ReloadOutlined onClick={() => { reloadFunction() }} /></span>;
            case "CHECKING":
                return <span style={{ marginLeft: "10px" }}><SyncOutlined spin /></span>;;

        }
    }
    return undefined;

}


export function fieldSorter(path: string, fields: Field[], config?: EntityTableViewConfig) {
    return function (a: { isRecordViewCandidate: boolean, field: Field }, b: { isRecordViewCandidate: boolean, field: Field }) {
        if (a.isRecordViewCandidate && !b.isRecordViewCandidate)
            return -1;

        if (!a.isRecordViewCandidate && b.isRecordViewCandidate)
            return 1;

        // we want sort by configuration or by lk or by name -- it is always deterministic
        let aPath = path == "" ? a.field.name : path + "." + a.field.name;
        let bPath = path == "" ? b.field.name : path + "." + b.field.name;

        // init equals sortering order
        let aval = 99999;
        let bval = 99999;

        // use a configuration order
        if (config && config.shortcuts && config.shortcuts.ordering) {
            let c_aval = config.shortcuts.ordering.indexOf(aPath)
            let c_bval = config.shortcuts.ordering.indexOf(bPath)
            aval = c_aval == -1 ? aval : c_aval;
            bval = c_bval == -1 ? bval : c_bval;
        }

        // no configuration found
        if (aval === bval) {
            const aIndex = fields.findIndex(f => f.name === a.field.name);
            const bIndex = fields.findIndex(f => f.name === b.field.name);

            if (aIndex >= 0 && bIndex >= 0)
                return aIndex - bIndex;
        }

        // if no ordering wins -- lets use the pathName
        const finalOrder = aval - bval;
        if (finalOrder === 0) {
            return aPath.localeCompare(bPath);
        }
        return finalOrder;
    }
}