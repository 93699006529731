import * as Components from "antd";
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as React from 'react';
import _ from "lodash";
import { entityDisplayName } from "../common/entityUtils";
import { getEntityRecordViewLocale } from "../common/locale";
import { getRecordLk } from "../common/recordUtils";
import { GeminiSchema } from '../common/schema';
import { EntityRecordEditConfig, EntityRecordViewProps, EntityReferenceConfigResolver, EntityReferenceRecordResolver, NewEntityRecordViewProps, RecordOperation } from '../common/types';
import { topValidation } from "../common/validationUtils";
import { recordStatusHeader, reloadButton } from "../entity-view/common";
import { PageViewConverter } from './recordDetailsConverters';
import { fromValuesToRecord, getFormInitialValues, PageEditConverter } from './recordEditConverter';
import { CopyOutlined, DownOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';

const titleFunction = (schema: GeminiSchema, record: any) => {
    return entityDisplayName(schema) + (schema.singleRecord ? "" : (": " + getRecordLk(record, schema)));
}

const EntityRecordModify = ({ record, schema, config, onCancel, onSubmit, onBack, recordsStatus, visibleStatus, oldStatusConfig, onReload, ...props }:
    {
        record: any, schema: GeminiSchema, config?: EntityRecordEditConfig,
        onCancel: () => void, onSubmit: (val: any) => any, onBack?: () => void,
        recordsStatus?: EntityRecordViewProps["recordsStatus"]
        visibleStatus?: EntityRecordViewProps["visibleStatus"]
        oldStatusConfig?: EntityRecordViewProps["oldStatusConfig"]
        onReload?: () => void
    }) => {
    const locale = getEntityRecordViewLocale(config);

    const extra = (formik: FormikProps<any>) => [
        <Components.Button key="undo" onClick={() => onCancel()}>{locale.recordOperations.update.cancelButton}</Components.Button>,
        <Components.Button key="submit" disabled={!formik.isValid} type="primary" htmlType="submit"><SaveOutlined />{locale.recordOperations.update.saveButton}</Components.Button>
    ]
    let subtitle = <div>
        {recordStatusHeader(recordsStatus, visibleStatus, oldStatusConfig, locale)}
        {reloadButton(recordsStatus, onReload)}
    </div>

    const initRec = getFormInitialValues(record, schema);
    return <div>
        <Formik key="form" initialValues={initRec}
            validate={topValidation(schema, config)}
            onSubmit={async (values) => {
                const newRecord = fromValuesToRecord(values, schema, config);
                onSubmit && await onSubmit(newRecord);
            }}>
            {formik => (
                <Form>
                    <Components.Spin spinning={formik.isSubmitting}>
                        <Components.PageHeader key="headerTitle" title={titleFunction(schema, record)} subTitle={subtitle} extra={extra(formik)} onBack={onBack}></Components.PageHeader>
                        <div className="padding-o-lg gemini-record-edit">
                            {<PageEditConverter initRecord={record} schema={schema} config={config} {...props}></PageEditConverter>}
                        </div>
                    </Components.Spin>
                </Form>
            )}
        </Formik>
    </div>
}

const EntityRecordDetails = (props: EntityRecordViewProps & { setEditing: (val: boolean) => void }) => {
    const { record, schema, config, setEditing, onBack = undefined, onRecordDelete, recordsStatus, visibleStatus, oldStatusConfig, onReload, entityReferenceConfigResolver, entityReferenceRecordResolver, onRecordClone, actionsStrategy, actions } = props;

    const [isDeleting, setDeleting] = React.useState(false);
    const containerRef = React.useRef(null);
    const locale = getEntityRecordViewLocale(config);

    const onDelete = async () => {
        setDeleting(true);
        onRecordDelete && await onRecordDelete({ record, lk: getRecordLk(record, schema) }, () => null, error(containerRef, locale.recordOperations?.delete.error))
    }

    let body = <PageViewConverter record={record} schema={schema} config={config} entityReferenceConfigResolver={entityReferenceConfigResolver} entityReferenceRecordResolver={entityReferenceRecordResolver} />

    let inActions: any[] = [];
    if (!schema.singleRecord) {
        if (!actionsStrategy || actionsStrategy === 'APPEND') {
            inActions.push(
                <Components.Menu.Item key="clone" icon={<CopyOutlined />} onClick={() => {
                    onRecordClone && onRecordClone({ record }, () => null, error(containerRef, locale.recordOperations?.clone.error))
                }} >
                    {locale.recordOperations.clone.button}
                </Components.Menu.Item>
            )
        }
    }
    inActions = actions ? inActions.concat(actions(props)) : inActions

    let extra = [
        inActions.length > 0 ? <Components.Dropdown key="actions" overlay={<Components.Menu>{inActions}</Components.Menu>}>
            <Components.Button>
                {locale.recordOperations.actions.button}<DownOutlined />
            </Components.Button>
        </Components.Dropdown> : null,
        schema.singleRecord ? null : <Components.Popconfirm key="delete" title={locale.recordOperations.delete.popConfirm.title} okText={locale.recordOperations.delete.popConfirm.ok} cancelText={locale.recordOperations.delete.popConfirm.cancel} onConfirm={onDelete}>
            <Components.Button key="delete" danger><DeleteOutlined />{locale.recordOperations.delete.button}</Components.Button>
        </Components.Popconfirm>,
        <Components.Button type="primary" key="edit" onClick={() => setEditing(true)}><EditOutlined />{locale.recordOperations.update.opeButton}</Components.Button>]

    let subtitle = <div>
        {recordStatusHeader(recordsStatus, visibleStatus, oldStatusConfig, locale)}
        {reloadButton(recordsStatus, onReload)}
    </div>
    let pageHeader = <Components.PageHeader key="headerTitle" title={titleFunction(schema, record)} subTitle={subtitle} onBack={onBack} extra={extra}></Components.PageHeader>

    return <div ref={containerRef}>
        <Components.Spin spinning={isDeleting}>
            {pageHeader}
            <div className="padding-o-lg gemini-record-view">
                {body}
            </div>
        </Components.Spin>
    </div>;
}


const error = (containerRef: React.MutableRefObject<unknown>, locale?: { title: string, content: string }) => (p?: { title?: string, content?: string }) => {
    const title = p?.title || locale?.title || "Error";
    const content = p?.content || locale?.content || "";
    Components.Modal.error({
        title: title,
        content: content,
        maskStyle: { position: "absolute" },
        getContainer: () => containerRef.current as unknown as HTMLElement,
    });
}

const EntityRecordView = (props: EntityRecordViewProps) => {
    const [isEditing, setEditing] = React.useState(false);
    const [stateRecord, setRecord] = React.useState(props.record);
    const containerRef = React.useRef(null);

    React.useEffect(() => {
        setRecord(props.record)
    }, [props.record])

    const locale = getEntityRecordViewLocale(props.config);

    async function onSubmit(rec: any) {
        // need lk of the original record (if the form changes lk values)
        const lk = getRecordLk(props.record, props.schema);
        const ok = () => {
            setRecord(rec);
            setEditing(false);
        }
        if (props.onRecordUpdate) {
            await props.onRecordUpdate({ record: rec, lk: lk }, ok, error(containerRef, locale.recordOperations.update.error));
        }
    }

    return <div ref={containerRef}>
        {isEditing ? <EntityRecordModify onSubmit={onSubmit} onCancel={() => setEditing(false)} {...props} record={stateRecord} ></EntityRecordModify>
            : <EntityRecordDetails setEditing={setEditing} {...props} record={stateRecord} ></EntityRecordDetails>}
    </div>
}

const EntityRecordNew = (props: NewEntityRecordViewProps) => {
    const { record, schema, config } = props;
    const containerRef = React.useRef(null);
    const locale = getEntityRecordViewLocale(config);


    const initRec = record ? getFormInitialValues(record, schema) : {}

    const extra = (formik: FormikProps<any>) => [
        <Components.Button key="submit" disabled={!formik.isValid || (_.isEmpty(initRec) && Object.keys(formik.touched).length === 0)} type="primary" htmlType="submit"><SaveOutlined />{locale.recordOperations.create.saveButton}</Components.Button>
    ]

    return <div>
        <Formik key="form" initialValues={initRec}
            validateOnMount={true}
            initialTouched={initRec}
            validate={topValidation(schema, config, initRec)}
            onSubmit={async (values: FormikHelpers<any>) => {
                const newRecord = fromValuesToRecord(values, schema, config);
                const lk = getRecordLk(newRecord, schema);
                await props.onRecordCreate({ record: newRecord, lk: lk }, () => null, error(containerRef, locale.recordOperations.create.error));
            }}>
            {formik => (
                <Form>
                    <Components.Spin spinning={formik.isSubmitting}>
                        <div ref={containerRef}>
                            <Components.PageHeader key="headerTitle" title="New Record" onBack={props.onBack} extra={extra(formik)}></Components.PageHeader>
                            <div className="padding-o-lg gemini-record-edit">
                                {<PageEditConverter {...props}></PageEditConverter>}
                            </div>
                        </div>

                    </Components.Spin>
                </Form>
            )}
        </Formik>
    </div>
}

export { EntityRecordView, EntityRecordNew };
