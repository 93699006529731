import { CaretDownOutlined } from '@ant-design/icons';
import { Empty, Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import React, { useEffect } from "react";
import { SelectProps } from 'antd/es/select';

export interface DebounceSelectProps<ValueType = any>
    extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
    fetchOptions: (search: string) => Promise<ValueType[]>;
    debounceTimeout?: number;
}

export function DebounceSelect<
    ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any
>({ fetchOptions, debounceTimeout = 500, ...props }: DebounceSelectProps) {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState<ValueType[]>([]);
    const fetchRef = React.useRef(0);

    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value).then(newOptions => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout, { leading: true, trailing: true });
    }, [fetchOptions, debounceTimeout]);

    useEffect(() => {
        debounceFetcher(props.value)
    }, [])

    return (
        <Select<ValueType>
            filterOption={false}
            onClear={() => debounceFetcher("")}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
            {...props}
            options={options}
            showArrow={true}
            suffixIcon={<CaretDownOutlined />}
        />
    );
}