
import { CaretDownOutlined, CaretUpOutlined, PlusOutlined } from '@ant-design/icons';
import * as Components from "antd";
import { FieldArray, FieldArrayRenderProps, FormikContextType, swap } from "formik";
import * as _ from "lodash";
import React from "react";
import { getEntityRecordViewLocale } from '../../common/locale';
import { Field, FieldType, SelectElem } from "../../common/schema";
import { RecordConverterFieldOverObject } from '../recordEditConverter';
import { entityRef_Fetch } from './entityRef';
import { EditFieldSpec, EditInnerState, FieldSpec, ViewFieldSpec } from './types';
import { recordConverterFieldOverObject } from '../recordDetailsConverters';
import { DebounceSelect } from './commons/debounceSelect';
import { EntityRecordEditConfig } from 'components/common/types';

export function arrayFieldEditConverter(_record: any, field: Field, formikContext: FormikContextType<unknown>, innerState: EditInnerState, config?: EntityRecordEditConfig, path: string = "") {
    const fieldName = path === "" ? field.name : path + "." + field.name;
    const fieldConfig = config?.fields?.[fieldName]
    const locale = getEntityRecordViewLocale(config);

    const arrayType = field.array!.type;

    const fieldArray = <FieldArray name={fieldName}>
        {({ form }: FieldArrayRenderProps) => {

            const arrayRecord = _.get(form.values, fieldName) || []
            const length = arrayRecord.length;
            const requiredAndEmpty = field.required && length === 0;

            const addFirstMessage = requiredAndEmpty ? locale.types.array.add_first_required : locale.types.array.add_first;

            const addButton = (defaultValue: any) => <Components.Button block danger={requiredAndEmpty} key="addtop" size="small"
                style={{ marginBottom: "10px" }}
                onClick={(event) => {
                    const arrayVal = _.get(form.values, fieldName) || []
                    const acopy = [...arrayVal]
                    acopy.splice(0, 0, defaultValue)
                    innerState.arraySwitchOpe[fieldName] = true
                    let actives = innerState.collapseActiveKeys[fieldName] || []
                    innerState.collapseActiveKeys[fieldName] = ["0"].concat(actives.map((v: string) => (Number.parseInt(v) + 1).toString()))
                    form.setFieldValue(fieldName, acopy)
                    event.stopPropagation();
                }}>{addFirstMessage}</Components.Button>


            const DeletButton = (index: number) => {
                return <Components.Button danger size="small" key="delete" onClick={(event) => {
                    const arrayVal = _.get(form.values, fieldName)
                    const acopy = [...arrayVal]
                    acopy.splice(index, 1)
                    innerState.arraySwitchOpe[fieldName] = true
                    let actives = innerState.collapseActiveKeys[fieldName] || []
                    innerState.collapseActiveKeys[fieldName] = actives.map((v: string) => Number.parseInt(v)).map((i: number) => i > index ? i - 1 : i).map(v => v.toString())
                    form.setFieldValue(fieldName, acopy)
                    event.stopPropagation();
                }}>Delete</Components.Button>
            }
            const UpButton = (index: number) => {
                return <Components.Button size="small" disabled={index === 0} icon={<CaretUpOutlined />} key="up" onClick={(event) => {
                    const arrayVal = _.get(form.values, fieldName)
                    const newAarray = swap(arrayVal, index - 1, index)
                    innerState.arraySwitchOpe[fieldName] = true
                    form.setFieldValue(fieldName, newAarray)
                    event.stopPropagation();
                }} />
            }
            const DownButton = (index: number) => {
                return <Components.Button size="small" disabled={index === (length - 1)} icon={<CaretDownOutlined />} key="down" onClick={(event) => {
                    const arrayVal = _.get(form.values, fieldName)
                    const newAarray = swap(arrayVal, index, index + 1)
                    innerState.arraySwitchOpe[fieldName] = true
                    form.setFieldValue(fieldName, newAarray)
                    event.stopPropagation();

                }} />
            }
            const PlusButton = (index: number, defaultValue: any) => {
                return <Components.Button size="small" icon={<PlusOutlined />} key="down" onClick={(event) => {
                    const arrayVal = _.get(form.values, fieldName)
                    const acopy = [...arrayVal]
                    acopy.splice(index + 1, 0, defaultValue)
                    innerState.arraySwitchOpe[fieldName] = true
                    let actives = innerState.collapseActiveKeys[fieldName] || []
                    innerState.collapseActiveKeys[fieldName] = [index.toString()].concat(actives.map((v: string) => Number.parseInt(v)).map((i: number) => i >= index ? i + 1 : i).map(v => v.toString()))
                    form.setFieldValue(fieldName, acopy)
                    event.stopPropagation();
                }} />
            }

            if (arrayType == FieldType.STRING) {

                if (fieldConfig?.displayType === "SELECT_TAGS") {
                    return <Components.Select mode="tags" style={{ width: "100%" }} onBlur={() => { form.setFieldTouched(fieldName, true, true) }}
                        value={arrayRecord} onChange={(selected) => { form.setFieldValue(fieldName, selected) }}
                        allowClear={!field.required} open={false} onInputKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                e.preventDefault();
                            }
                        }}>
                    </Components.Select>


                } else {

                    let res: any[] = []
                    arrayRecord.forEach((value: any, index: number) => {
                        const innerPath = fieldName + "." + index;
                        const errorTag = _.get(formikContext.errors, innerPath);
                        const touched = _.get(formikContext.touched, innerPath);
                        const errorTouchedClasses = (errorTag ? "gemini-field-error" : "") + " " + (touched ? "touched" : "")
                        const finalClasses = errorTouchedClasses; // concatente others here if necessary

                        const RowBody = () => {
                            // TODO ADD debounce 

                            if (fieldConfig?.displayType === "TEXTAREA") {
                                return <Components.Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} {...fieldConfig.textAreaConfig}
                                    defaultValue={value}
                                    onBlur={(e) => { form.setFieldTouched(innerPath, true, false); form.setFieldValue(innerPath, e.target.value) }} />;
                            }

                            return <Components.Input key={innerPath} defaultValue={value}
                                onBlur={(e) => { form.setFieldTouched(innerPath, true, false); form.setFieldValue(innerPath, e.target.value) }}
                            />;
                        }


                        const row = <Components.Row key={index} gutter={[4, 8]} align="middle">
                            <Components.Col className={finalClasses} flex="auto">
                                <RowBody></RowBody>
                            </Components.Col>
                            <Components.Col flex="none">
                                {UpButton(index)}
                            </Components.Col>
                            <Components.Col flex="none">
                                {DownButton(index)}
                            </Components.Col>
                            <Components.Col flex="none">
                                {PlusButton(index, "")}
                            </Components.Col>
                            <Components.Col flex="none">
                                {DeletButton(index)}
                            </Components.Col>
                        </Components.Row>
                        res.push(row)
                    });
                    return <div style={{ width: "100%" }}>
                        <div style={{ marginBottom: "8px" }}>{addButton("")}</div>
                        {res}
                    </div>;
                }

            }

            if (arrayType === FieldType.ENUM || arrayType === FieldType.SELECT) {
                let options = null
                if (arrayType == FieldType.ENUM) {
                    options = field.array!.enums!.map((value: any, index: number) =>
                        <Components.Select.Option key={index} value={value}>{value}</Components.Select.Option>
                    )
                }
                if (arrayType == FieldType.SELECT) {
                    options = field.array!.select!.elems.map((elem: SelectElem, index: number) =>
                        <Components.Select.Option key={index} value={elem.value}>{elem.displayName}</Components.Select.Option>
                    )
                }

                const handleChange = (value: any) => { form.setFieldValue(fieldName, value) };


                return <Components.Select mode="multiple" style={{ width: '100%' }} placeholder={addFirstMessage} onBlur={() => form.setFieldTouched(fieldName, true, false)}
                    allowClear={!field.required}
                    defaultValue={arrayRecord} onChange={handleChange}>
                    {options}
                </Components.Select>
            }

            if (arrayType === FieldType.ENTITY_REF) {

                return <DebounceSelect
                    mode="multiple"
                    style={{ width: "100%" }} onBlur={() => { form.setFieldTouched(field.name, true, false) }}
                    value={arrayRecord} onChange={(selected) => { form.setFieldValue(fieldName, selected) }}
                    allowClear={!field.required}
                    showSearch
                    fetchOptions={async (searchValue: string) => {
                        return entityRef_Fetch(searchValue, field.array?.entityRef!, innerState);
                    }}
                />

            }

            if (arrayType === FieldType.OBJECT) {
                let res: any[] = []
                const fields = field.array!.object!.fields
                if (!innerState.ids)
                    innerState.ids = {}
                const ids = innerState.ids;
                let panelItems: any[] = [];
                let collapseKey = _.get(ids, fieldName)
                if (!collapseKey || (innerState.arraySwitchOpe[fieldName] == true)) {
                    collapseKey = randomId();
                    _.set(ids, fieldName, collapseKey)
                    innerState.arraySwitchOpe[fieldName] = false;
                }

                arrayRecord.forEach((value: any, index: number) => {
                    const innerPath = fieldName + "." + index;
                    let key = _.get(ids, innerPath)
                    if (!key) {
                        key = randomId();
                        _.set(ids, innerPath, key);
                    }

                    let headerText = index;
                    if (fieldConfig && fieldConfig.headerFun && typeof fieldConfig.headerFun === "function")
                        headerText = fieldConfig.headerFun(index);
                    let header = <div style={{ display: "inline-block", width: "calc(100% - 30px)", float: "right" }}><Components.Row gutter={4} align="middle">
                        <Components.Col flex="auto">{headerText}</Components.Col>
                        <Components.Col flex="none">
                            {UpButton(index)}
                        </Components.Col>
                        <Components.Col flex="none">
                            {DownButton(index)}
                        </Components.Col>
                        <Components.Col flex="none">
                            {PlusButton(index, {})}
                        </Components.Col>
                        <Components.Col flex="none">
                            {DeletButton(index)}
                        </Components.Col>
                    </Components.Row></div>



                    const itemPanel = <Components.Collapse.Panel key={index} header={header} >
                        <RecordConverterFieldOverObject recordAtLevel={value} fields={fields} formikContext={formikContext} innerState={innerState} config={config} path={innerPath}></RecordConverterFieldOverObject>
                    </Components.Collapse.Panel>
                    panelItems.push(itemPanel)
                })

                const onChange = (activeKeys: any) => {
                    innerState.collapseActiveKeys[fieldName] = activeKeys;
                }

                if (panelItems.length > 0) {
                    const activeKey = innerState.collapseActiveKeys[fieldName] || [];
                    const collapse = <Components.Collapse key={collapseKey} defaultActiveKey={activeKey} onChange={onChange} ghost>{panelItems}</Components.Collapse>
                    res.push(collapse)
                }
                return <div style={{ width: "100%" }}>
                    {addButton({})}
                    {res}
                </div>;
            }


            console.error(`FieldType ${arrayType} not implemented yet`)
            return null;
        }}
    </FieldArray>
    return fieldArray;
}


function randomId() {
    return Math.random().toString(36).substring(7)
}

export const ViewFieldComponent = (props: ViewFieldSpec) => {
    const { value, field, path, fieldConfig, innerState, config } = props
    if (value != null && Array.isArray(value)) {
        const arrayType = field.array!.type
        const innerPath = field ? (path === "" ? field.name : path + "." + field.name) : "";

        if (arrayType == FieldType.OBJECT) {
            const fields = field.array!.object!.fields!;
            let panelItems = []
            for (let i = 0; i < value.length; i++) {
                let ivalue = value[i];
                let header = i;
                if (fieldConfig && fieldConfig.headerFun && typeof fieldConfig.headerFun === "function")
                    header = fieldConfig.headerFun(i);
                panelItems.push(<Components.Collapse.Panel key={i} header={header}>{recordConverterFieldOverObject(ivalue, fields, innerState, config, innerPath)}</Components.Collapse.Panel>)

            }

            let res = [];
            if (panelItems.length > 0) {
                const collapse = <Components.Collapse ghost key={'array_' + field.name}>{panelItems}</Components.Collapse>
                res.push(collapse)
            }
            return res;
        }

        if (arrayType === FieldType.ENUM) {
            let res = [];
            for (let i = 0; i < value.length; i++) {
                res.push(<Components.Tag key={i}>{value[i]}</Components.Tag>)
            }
            return res;
        }

        if (arrayType === FieldType.SELECT) {
            let res = [];
            for (let i = 0; i < value.length; i++) {
                const selectElem = field.array!.select!.elems.find(e => e.value === value[i]);
                res.push(<Components.Tag key={i} >{selectElem?.displayName}</Components.Tag>)
            }
            return res;
        }

        if (arrayType === FieldType.STRING) {

            if (fieldConfig?.displayType === "SELECT_TAGS") {
                let res = [];
                for (let i = 0; i < value.length; i++) {
                    res.push(<Components.Tag key={i} >{value[i]}</Components.Tag>)
                }
                return res;
            }

            return <Components.List style={{ width: "100%" }}
                size="small"
                dataSource={value}
                renderItem={item => <Components.List.Item><span style={{ whiteSpace: "pre-wrap" }}>{item}</span></Components.List.Item>}
            />
        }
    }
    return "";
}


export const EditFieldComponent = (props: EditFieldSpec) => {
    const { value, field, formikContext, innerState, config, path } = props;
    return arrayFieldEditConverter(value, field, formikContext, innerState, config, path)
}

export default { EditFieldComponent, ViewFieldComponent } as FieldSpec
