
import * as Components from "antd";
import React, { useMemo } from "react";
import { getEntityRecordViewLocale } from "../common/locale";
import { activeField } from "../common/recordUtils";
import { Field, FieldType, GeminiSchema } from "../common/schema";
import { EntityRecordViewConfig, EntityReferenceConfigResolver, EntityReferenceRecordResolver } from "../common/types";
import { fieldSorter, getConfigPath } from "./recordUtils";
import { TYPES_RESOLVERS } from "./typeConverters";
import { ViewInnerState } from "./typeConverters/types";

const PageViewConverter = ({ record, schema, config, entityReferenceConfigResolver, entityReferenceRecordResolver }:
    {
        record: any, schema: GeminiSchema, config?: EntityRecordViewConfig
        entityReferenceConfigResolver?: EntityReferenceConfigResolver
        entityReferenceRecordResolver?: EntityReferenceRecordResolver
    }) => {
    const fields = schema!.fields;

    const innerState = useMemo<ViewInnerState>(() => {
        return { initRecord: record, schema, entityReferenceConfigResolver, entityReferenceRecordResolver }
    }, [schema, entityReferenceConfigResolver, entityReferenceRecordResolver])

    return <>{recordConverterFieldOverObject(record, fields, innerState, config, "")}</>
}

function recordConverterField(value: any, field: Field, innerState: ViewInnerState, config?: EntityRecordViewConfig, path: string = "") {
    const fieldName = field ? (path == "" ? field.name : path + "." + field.name) : "";
    const fieldConfig = config?.fields?.[getConfigPath(fieldName)]
    const locale = getEntityRecordViewLocale(config);
    const fieldType = field.type;

    // RESOLVER for the COMMON TYPES - (strings, dates, bool, and so on..)
    const resolver = TYPES_RESOLVERS[fieldType];
    if (resolver) {
        const ViewFieldComponent = resolver.ViewFieldComponent;
        if (ViewFieldComponent) {
            const props = { value, locale, field, fieldName, fieldConfig, path, innerState, config }
            return <ViewFieldComponent {...props} />
        }
    }

    return null;
}

function resolveCurrentElems(e: {
    lastElems: any[],
    lastElemType: 'DESCRIPTION' | 'COLLAPSE' | 'TABS' | undefined,
    result: any[]
}, count: number, config?: EntityRecordViewConfig) {

    if (e.lastElemType === 'DESCRIPTION') {
        const description = <Components.Descriptions key={`description_${count}`} column={config?.defaults?.descriptions?.column || 1} bordered={config?.defaults?.descriptions?.bordered || false} size={config?.defaults?.descriptions?.size || 'default'}>{e.lastElems}</Components.Descriptions>
        e.result.push(description)
    }

    if (e.lastElemType === 'TABS') {
        const tabs = <Components.Tabs key={`tabs_${count}`}>{e.lastElems}</Components.Tabs>
        e.result.push(tabs)
        e.result.push(<Components.Divider key={`divider_${count}`}></Components.Divider>)
    }

    return { lastElems: [], lastElemType: undefined, result: e.result }
}

function recordConverterFieldOverObject(recordAtLevel: any, fields: Field[], innerState: ViewInnerState, config?: EntityRecordViewConfig, path: string = "") {
    var newFields = [...fields];
    newFields.sort(fieldSorter(path, fields, config))

    let currentState: any = {
        lastElems: [],
        lastElemType: undefined,
        result: []
    }

    let count = 0;
    for (let innerF of newFields) {
        const fieldName = path == "" ? innerF.name : path + "." + innerF.name;

        // TODO possibile bug here, we need to propagate also the full record
        if (!activeField(innerState.initRecord, fieldName, config))
            continue

        const key = innerF.name
        const value = recordAtLevel && recordAtLevel[innerF.name];
        const label = innerF.displayName || key;

        if ([FieldType.INTEGER, FieldType.DOUBLE, FieldType.BOOL, FieldType.STRING, FieldType.ENUM, FieldType.SELECT, FieldType.B64_IMAGE, FieldType.ANY, FieldType.DATE, FieldType.ENTITY_REF].indexOf(innerF.type) >= 0){
            if (currentState.lastElemType && currentState.lastElemType != 'DESCRIPTION')
            currentState = resolveCurrentElems(currentState, count, config);

            currentState.lastElemType = 'DESCRIPTION'
            currentState.lastElems.push(
                <Components.Descriptions.Item key={key} label={<b>{label}</b>}>{
                    recordConverterField(value, innerF, innerState, config, path)}
                    </Components.Descriptions.Item>
            )

        }

        if ([FieldType.OBJECT, FieldType.DICTIONARY].indexOf(innerF.type) >= 0) {
            let sectionType = "COLLAPSE"; // code default
            sectionType = (innerF.type == FieldType.OBJECT && config && config.defaults && config.defaults.objectFields && config.defaults.objectFields.sectionType) || sectionType;
            if (config && config.fields) {
                const fieldConfig = config.fields[getConfigPath(fieldName)];
                if (fieldConfig && fieldConfig.sectionType)
                    sectionType = fieldConfig.sectionType;
            }

            if (sectionType == "COLLAPSE") {

                currentState = resolveCurrentElems(currentState, count, config);
                const configuredGhost = config?.defaults?.collapse?.ghost;
                const ghost = configuredGhost === undefined ? true : configuredGhost;
                const collapsible = innerF.type === FieldType.DICTIONARY && (!value || Object.keys(value).length === 0) ? "disabled" : "header";

                currentState.result.push(
                    <Components.Collapse key={'collapse_' + fieldName} collapsible={collapsible} bordered={config?.defaults?.collapse?.bordered || false} ghost={ghost} defaultActiveKey={[key]}>

                        <Components.Collapse.Panel key={key} header={<b>{label}</b>} extra={null}>
                            {recordConverterField(value, innerF, innerState, config, path)}
                        </Components.Collapse.Panel>
                    </Components.Collapse>
                )
            }


            if (sectionType == "TAB") {
                if (currentState.lastElemType && currentState.lastElemType != 'TABS')
                    currentState = resolveCurrentElems(currentState, count, config);

                currentState.lastElemType = 'TABS'
                currentState.lastElems.push(
                    <Components.Tabs.TabPane key={key} tab={label}>
                        {recordConverterField(value, innerF, innerState, config, path)}
                    </Components.Tabs.TabPane>
                )
            }

        }

        if (innerF.type === FieldType.ARRAY) {
            const arrayType = innerF.array!.type

            let sectionType = config?.defaults?.arrayFields?.sectionType || "DESCRIPTION"
            if (sectionType == "DESCRIPTION") {

                if (currentState.lastElemType && currentState.lastElemType != 'DESCRIPTION')
                    currentState = resolveCurrentElems(currentState, count, config);

                currentState.lastElemType = 'DESCRIPTION'
                currentState.lastElems.push(
                    <Components.Descriptions.Item key={key} label={<b>{label}</b>}>
                        {recordConverterField(value, innerF, innerState, config, path)}
                    </Components.Descriptions.Item>)
            }
        }
        count += 1;
    }

    currentState = resolveCurrentElems(currentState, count, config);
    const lastElemKey: string = currentState.result[currentState.result.length - 1].key;
    if (lastElemKey.startsWith("divider"))
        currentState.result.pop()
    return <React.Fragment>
        {currentState.result}
    </React.Fragment>
}



export default { PageViewConverter, recordConverterFieldOverObject }
export { PageViewConverter, recordConverterFieldOverObject };
