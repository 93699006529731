import { Select } from "antd";
import { FastField, FieldProps } from "formik";
import React from "react";
import { SelectElem } from "../../common/schema";
import { EditFieldSpec, FieldSpec, ViewFieldSpec } from "./types";

export const EditFieldComponent = (props: EditFieldSpec) => {
    const { fieldName, field } = props
    return <FastField name={fieldName}>
        {(fp: FieldProps) => {
            const { value, ...propsNotValue } = fp.field;
            return <Select style={{ width: "100%" }} {...propsNotValue} onBlur={() => { fp.form.setFieldTouched(fp.field.name, true, true) }}
                value={fp.field.value} onChange={(selected) => { fp.form.setFieldValue(fp.field.name, selected) }}
                allowClear={!field.required} >
                {field.select?.elems.map((elem: SelectElem) => <Select.Option key={elem.value} value={elem.value}>{elem.displayName}</Select.Option>)}
            </Select>
        }}
    </FastField>
}

export const ViewFieldComponent = (props: ViewFieldSpec) => {
    const { value, field } = props
    if (typeof value === 'string') {
        const selectElem = field.select!.elems.find(e => e.value === value);
        return selectElem?.displayName;
    }
    return value ?? null;
}


export default { EditFieldComponent, ViewFieldComponent } as FieldSpec
