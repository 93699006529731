import { EntityViewConfig } from "@gemini-projects/gemini-react-entity-lib/dist/components/common/types";
import { EditFieldSpec } from "@gemini-projects/gemini-react-entity-lib/dist/components/entityrecord-view/typeConverters/types";
import { Select } from "antd";
import { Field, FieldProps } from "formik";
import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import entityManagerService from 'services/entityManager.service';
import CustomComponentsContext from '../../../contexts/customComponents.context';



const entityConfigEntity_editRender = (props: EditFieldSpec) => {
    const { fieldName, field, formikContext } = props
    const actualRec = formikContext.values;
    const namespace = _.get(actualRec, "entityConfig.namespace");

    const [options, setOptions] = React.useState([])

    useEffect(() => {
        async function load() {
            const schemas = await entityManagerService.getSchemas(namespace)
            setOptions(schemas.map((s) => ({ value: s.name, label: s.displayName })))
            formikContext.setFieldValue(fieldName, _.get(actualRec, "entityConfig.entity"))
        }
        load();
    }, [namespace])

    return <Field name={fieldName}>
        {(fp: FieldProps) => {
            const { value, ...propsNotValue } = fp.field;
            return <Select style={{ width: "100%" }} {...propsNotValue} onBlur={() => { fp.form.setFieldTouched(fp.field.name, true, true) }}
                value={fp.field.value} onChange={(selected) => { fp.form.setFieldValue(fp.field.name, selected) }}
                allowClear={!field.required} options={options}
            >
            </Select>
        }}
    </Field>
}

const customComponentConfigcomponent_editRender = (props: EditFieldSpec) => {
    const { fieldName, field, formikContext } = props
    const actualRec = formikContext.values;
    // const component = _.get(actualRec, "customComponentConfig.component");

    const components = useContext(CustomComponentsContext);
    const options: any[] = []
    for(const key in components) {
        const c = components[key]
        options.push({label: c.displayName, value: key})
    }

    //const options = components.

    return <Field name={fieldName}>
        {(fp: FieldProps) => {
            const { value, ...propsNotValue } = fp.field;
            return <Select style={{ width: "100%" }} {...propsNotValue} onBlur={() => { fp.form.setFieldTouched(fp.field.name, true, true) }}
                value={fp.field.value} onChange={(selected) => { fp.form.setFieldValue(fp.field.name, selected) }}
                allowClear={!field.required} options={options}
            >
            </Select>
        }}
    </Field>
}


export default {
    recordView: {
        fields: {
            'entityConfig.entity': {
                editValueRender: entityConfigEntity_editRender
            },
            'customComponentConfig.component': {
                editValueRender: customComponentConfigcomponent_editRender
            }
        }

    }
} as EntityViewConfig