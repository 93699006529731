



import { Select } from "antd";
import { FastField, FieldProps } from "formik";
import React from "react";
import { EditFieldSpec, FieldSpec, ViewFieldSpec } from "./types";



export const EditFieldComponent = (props: EditFieldSpec) => {
    const { fieldName, field } = props

    return <FastField name={fieldName}>
        {(fp: FieldProps) => {
            const { value, ...propsNotValue } = fp.field;
            return <Select style={{ width: "100%" }} {...propsNotValue} onBlur={() => { fp.form.setFieldTouched(fp.field.name, true, true) }}
                value={fp.field.value} onChange={(selected) => { fp.form.setFieldValue(fp.field.name, selected) }}
                allowClear={!field.required}
            >
                {field.enums?.map((val: string) => <Select.Option key={val} value={val}>{val}
                </Select.Option>)}
            </Select>
        }}
    </FastField>

}

export const ViewFieldComponent = (props: ViewFieldSpec) => {
    const { value } = props
    if (typeof value === 'string')
        return value
    if (value != null)
        return new String(value);
    return "";
}




export default { EditFieldComponent, ViewFieldComponent } as FieldSpec