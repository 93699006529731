import * as _ from "lodash";
import { EntityAllViewsConfig, EntityRecordViewConfig, EntityTableViewConfig, GlobalLocale } from "./types";

const en: GlobalLocale = {
    code: "en",
    fields: {
        required: "Required",
        invalid_json: "Invalid JSON",
        invalid_javascript: "Invalid Javascript Code",
        duplicateLk: "This value already exists"
    },
    tableView: {
        treeFieldSettings: {
            selectAll: "Select All"
        }
    },
    types: {
        array: {
            add_first: "Add first element",
            add_first_required: "Add first element (required)"
        },
        date: {
            format: "YYYY-MM-DD"
        },
        geohash_location: {
            pick: "Pick On Map",
            byLocation: "By Location",
            locationPlaceholder: "Insert a location here"
        }
    },
    recordOperations: {
        actions: {
            button: "Actions"
        },
        clone: {
            button: "Clone",
            error: {
                title: "Clone Error",
                content: "Unable to clone the record. Some errors occurs"
            }
        },
        create: {
            opeButton: "New",
            saveButton: "Create",
            error: {
                title: "Create Error",
                content: "Unable to create the record. Some errors occurs"
            }
        },
        delete: {
            button: "Delete",
            popConfirm: {
                title: "Are you sure?",
                ok: "Ok",
                cancel: "No"
            },
            error: {
                title: "Delete Error",
                content: "Unable to delete the record. Some errors occurs"
            }
        },
        update: {
            opeButton: "Edit",
            saveButton: "Save",
            cancelButton: "Cancel",
            error: {
                title: "Update Error",
                content: "Unable to update the record. Some errors occurs"
            }
        }
    },
    recordsStatus: {
        updating: "Checking for updates",
        updated: "Updated",
        lastCheck: "Last check "
    },
    quickFilter: "Quick filter"
}

const defaultLocale = {
    "en": en
}


export function getEntityRecordViewLocale(erc?: EntityRecordViewConfig | EntityTableViewConfig): GlobalLocale {
    let defLocale = defaultLocale["en"]
    if (erc && erc.locale && erc.locale.code) {
        defLocale = defaultLocale[erc.locale.code] || defLocale;
        return _.merge(erc.locale, defLocale);
    }
    return defLocale;
}


export function getEntityTableViewLocale(erc?: EntityTableViewConfig) {
    let defLocale = defaultLocale["en"]
    if (erc && erc.locale && erc.locale.code) {
        defLocale = defaultLocale[erc.locale.code] || defLocale;
        return _.merge(erc.locale, defLocale);
    }
    return defLocale;
}

export function getEntityViewLocale(erc?: EntityAllViewsConfig) {
    let defLocale = defaultLocale["en"]
    if (erc && erc.locale && erc.locale.code) {
        defLocale = defaultLocale[erc.locale.code] || defLocale;
        return _.merge(erc.locale, defLocale);
    }
    return defLocale;
}