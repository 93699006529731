import { SearchOutlined } from '@ant-design/icons';
import * as Components from "antd";
import dayjs, { Dayjs } from "dayjs";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { convertRecordsToTree } from "../common/entityUtils";
import { getEntityRecordViewLocale } from '../common/locale';
import { getRecordLk, getRecordReferenceDisplayValue } from "../common/recordUtils";
import { Field, FieldType, GeminiSchema } from "../common/schema";
import { EntityTableViewConfig, EntityTableViewProps, EntityViewConfig } from "../common/types";
import { fieldSorter } from './common';

const defaultConfig = {
    tableTag: {
        color: "geekblue",
        true: "green",
        false: "red"
    }
}

function getSorter(f: Field, fieldFullName: string) {

    if (f.type === FieldType.INTEGER)
        return (a: any, b: any) => {
            let fa = _.get(a, fieldFullName) as number;
            let fb = _.get(b, fieldFullName) as number;
            if (!fa)
                return 1;
            if (!fb)
                return -1;
            return fa - fb;
        }

    if (f.type === FieldType.STRING || f.type === FieldType.ENUM) {
        return (a: any, b: any) => {
            let fa = _.get(a, fieldFullName) as string;
            let fb = _.get(b, fieldFullName) as string;
            if (!fa)
                return 1;
            if (!fb)
                return -1;
            fa = fa.toLocaleLowerCase()
            fb = fb.toLocaleLowerCase()
            return fa.localeCompare(fb)
        };
    }

    if (f.type === FieldType.DATE) {
        return (a: any, b: any) => {
            let fa = _.get(a, fieldFullName) as any;
            let fb = _.get(b, fieldFullName) as any;
            let fad: Dayjs = dayjs(fa as any);
            let fbd: Dayjs = dayjs(fb as any);
            return fad.diff(fbd);
        }
    }

    return false;
}

const EntityRefTableViewConverter = ({ field, tableProps, value }: { field: Field, tableProps: EntityTableViewProps, value: string }) => {
    const [refConfiguration, setConfiguration] = useState<{ loaded: boolean, schema?: GeminiSchema, config?: EntityViewConfig, value?: any }>({ loaded: false });


    useEffect(() => {
        getRecordReferenceDisplayValue(field, value, setConfiguration, tableProps.entityReferenceConfigResolver, tableProps.entityReferenceRecordResolver)
    }, [value])

    if (!refConfiguration.loaded)
        return null;

    // not need to resolve object we can use "LK"
    return <>{refConfiguration.value}</>;
}


const recursiveTableSorter = (columns: any[], path: string, fields: Field[], config: EntityTableViewConfig) => {
    columns.sort(fieldSorter(path, fields, config))
    columns.forEach((c: { field: Field, children: any[] }) => {
        if (c.field.type === FieldType.OBJECT && Array.isArray(c.children)) {
            const newPath = path == "" ? c.field.name : path + "." + c.field.name
            recursiveTableSorter(c.children, newPath, c.field.object!.fields, config)
        }
    });
}

const TableViewConverter = (props: any) => {
    const config = props.config;
    const columns = makeColumns(props.schema.fields, props)
    const fields = props.schema.fields;
    const [pagination, setPagination] = useState(props.defaultPagination)

    useEffect(() => {
        if (props.pagination && !_.isEmpty(props.pagination))
            setPagination(props.pagination)
    }, [props.pagination])


    // sort field columns
    if (columns.length > 0) {
        recursiveTableSorter(columns, "", fields, config);
    }

    let records = props.records;
    if (props.schema.tree && props.schema.tree.enabled) {
        records = convertRecordsToTree(props.records, props.schema.lk[0], props.schema.tree.parentField);
    }

    return <Components.Table rowKey={r => getRecordLk(r, props.schema)} columns={columns} dataSource={records}
        bordered scroll={{ x: 'max-content' }}
        onRow={props.onRow} onChange={props.onChange} size={props.size}
        pagination={pagination} />
}


function makeColumns(fields: Field[], props: { activeFields: string[] }, path: string = "") {
    let columns = []
    const activeFields = props.activeFields;

    for (let f of fields) {
        const fieldKey = path == "" ? f.name : path + "." + f.name;
        if (activeFields.includes(fieldKey)) {
            const columnDef = makeColumnField(f, props as any, path)
            if (columnDef)
                columns.push(columnDef)
        }
    }
    return columns;
}

function makeColumnField(field: Field, props: EntityTableViewProps & { activeFields: string[] }, path: string = ""): object | undefined {
    var fieldName = path == "" ? field.name : path + "." + field.name;
    const config = props.config;
    const fieldConfig = config?.fields?.[fieldName]
    const title = field.displayName || field.name;
    const locale = getEntityRecordViewLocale(props.config);


    // exclude parent field (must be in the root)
    if (props.schema.tree && props.schema.tree.enabled && field.name === props.schema.tree.parentField)
        return undefined

    let sorter = getSorter(field, fieldName);

    if ([FieldType.ANY].indexOf(field.type) >= 0) {
        // TODO render by field type
        let res = {
            title: title,
            key: fieldName,
            dataIndex: fieldName.split("."),
            sorter: sorter,
            field: field
        }

        return { ...res, ...getColumnSearchProps(fieldName) }
    }

    if ([FieldType.STRING, FieldType.INTEGER, FieldType.DOUBLE].indexOf(field.type) >= 0) {
        let res = {
            title: title,
            key: fieldName,
            dataIndex: fieldName.split("."),
            sorter: sorter,
            field: field
        }

        // if clicked it opens the view
        let isRecordViewCandidate = (config && config.recordViewField && config.recordViewField.toLowerCase() === field.name.toLowerCase())
            || (!config && props.schema.lk && props.schema.lk.indexOf(field.name.toLowerCase()) > -1)
            || (config && !config.recordViewField && props.schema.lk && props.schema.lk.indexOf(field.name.toLowerCase()) > -1)
        if (isRecordViewCandidate)
            res['render'] = (text: string, record: any, index: number) => <a onClick={() => props.onRecordView && props.onRecordView(text, record, index)}>{text}</a>;
        res['isRecordViewCandidate'] = isRecordViewCandidate

        if (fieldConfig && fieldConfig.displayType == "JSON") {
            res['render'] = (text: string, _record: any, _index: number) => {
                if (text && text != "")
                    return <ReactJson src={JSON.parse(text)} name={false} collapsed={true} enableClipboard={false} displayDataTypes={false} displayObjectSize={false} />
                return undefined
            }
        }

        res = { ...res, ...getColumnSearchProps(fieldName) }

        return res;
    }

    if (field.type == FieldType.BOOL) {
        return {
            field: field,
            title: title,
            key: fieldName,
            dataIndex: fieldName.split("."),
            sorter: sorter,
            filters: [{
                text: "True", value: true
            },
            {
                text: "False", value: false
            }],
            onFilter: (value: boolean, record: any) => _.get(record, fieldName, false) === value ,
            render: (boolVal: boolean) =>
                <Components.Tag color={boolVal ? "green" : "red"}>
                    {boolVal ? "True" : "False"}
                </Components.Tag>

        }
    }

    if (field.type == FieldType.ENUM) {

        // TODO enum color in page props? custom render could be better ?
        // TODO filter by

        const filters = field.enums!.map(f => { return { text: f, value: f } })
        const onFilter = (value: string, record: any) => _.get(record, fieldName).indexOf(value) === 0

        return {
            title: title,
            key: fieldName,
            dataIndex: fieldName.split("."),
            sorter: sorter,
            filters: filters,
            field: field,
            onFilter: onFilter,
            render: (enumValue: string) =>
                <Components.Tag color={defaultConfig.tableTag.color} key={enumValue}>
                    {enumValue}
                </Components.Tag>

        }
    }

    if (field.type == FieldType.SELECT) {
        const filters = field.select!.elems.map(s => { return { text: s.displayName, value: s.value } })
        const onFilter = (value: string, record: any) => _.get(record, fieldName, "").indexOf(value) === 0

        return {
            title: title,
            key: fieldName,
            dataIndex: fieldName.split("."),
            sorter: sorter,
            filters: filters,
            field: field,
            onFilter: onFilter,
            render: (selectValue: string) => {
                if (selectValue) {
                    const foundSelect = field.select!.elems.find(e => e.value === selectValue);
                    if (foundSelect)
                        return <Components.Tag color={defaultConfig.tableTag.color} key={foundSelect?.value}>
                            {foundSelect?.displayName}
                        </Components.Tag>
                }
                return null
            }

        }
    }

    if (field.type == FieldType.DATE) {

        // TODO filter

        return {
            title: title,
            key: fieldName,
            dataIndex: fieldName.split("."),
            sorter: sorter,
            field: field,
            // filters: [],
            //onFilter: (value: boolean, record: any) => null,
            render: (date: any) => {
                if (Array.isArray(date) || date instanceof Date) {
                    return dayjs(date as any).format(locale.types.date.format)
                }
                return date; // TODO
            }

        }
    }

    if (field.type === FieldType.OBJECT) {
        return {
            title: title,
            key: fieldName,
            field: field,
            children: makeColumns(field.object!.fields, props, fieldName)
        }
    }

    // TODO b64 Image

    if (field.type === FieldType.ENTITY_REF) {

        // todo get schema before ??

        return {
            title: title,
            key: fieldName,
            dataIndex: fieldName.split("."),
            field: field,
            render: (v: any) => {
                return <EntityRefTableViewConverter tableProps={props} value={v} field={field} /*path={path}*/ />
            }
        }
    }

    console.error(`Field ${field.name} not handled`)

    return undefined;
}




const getColumnSearchProps = (dataIndex: string) => {
    let searchInput: any;
    return ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: { setSelectedKeys: any, selectedKeys: any, confirm: any, clearFilters: any }) => {
            return (
                <div style={{ padding: 8 }}>
                    <Components.Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Search`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Components.Space>
                        <Components.Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={< SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
          </Components.Button>
                        <Components.Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Reset
          </Components.Button>
                    </Components.Space>
                </div>
            )
        },
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) => _.get(record, dataIndex, "").toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        }
    })
}

const handleSearch = (_selectedKeys: any, confirm: any, _dataIndex: any) => {
    confirm();
};

const handleReset = (clearFilters: any) => {
    clearFilters();
};


export { TableViewConverter };
