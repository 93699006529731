import EntityManager from "./entityManager.service";

export const getDataURI = getGenericURI("data")
export const getSchemaURI = getGenericURI("schema")

export const DEFAULT_NAMESPACE = 'DEFAULT';
export const DEFAULT_NAMESPACE_URI = '';
export const NAMESPACE_URI: { [key: string]: string } = {};


function getGenericURI(pattern: string): (props: any) => Promise<string> {

    // todo check pattern

    return async function (props: string | {
        namespace?: string
        namespaceUri?: string
        entity: string
        lk?: string
        schema?: { name: string }
    }) {
        if (typeof props == "string") {
            const tolower = props.toLowerCase()
            return `${DEFAULT_NAMESPACE_URI}/${pattern}/${tolower}`
        }

        if (typeof props == "object") {
            let namespace = props.namespace;
            let namespaceUri = props.namespaceUri;
            let entityname = props.entity;
            const schema = props.schema;
            const reclk = props.lk;

            if (namespace) {
                namespaceUri = await getNamespaceURI(namespace);
            }

            if (!namespace && (!namespaceUri || namespaceUri === "")) {
                namespaceUri = DEFAULT_NAMESPACE_URI;
            }


            if (!entityname || entityname === "") {
                if (schema)
                    entityname = schema.name;
            }

            if (!entityname)
                throw new Error("Entity name not found");

            entityname = entityname.toLowerCase();

            if (reclk)
                return `${namespaceUri}/${pattern}/${entityname}/${reclk}`
            return `${namespaceUri}/${pattern}/${entityname}`
        }

        throw new Error("props not valid")
    }
}

export async function getNamespaceSchemaURI(namespace: string): Promise<string> {
    if (!namespace)
        return `${DEFAULT_NAMESPACE_URI}/schema`

    const namespaceURI = await getNamespaceURI(namespace);
    return `${namespaceURI}/schema`
}

export async function getNamespaceURI(namespace: string): Promise<string> {

    if (!NAMESPACE_URI[namespace]) {
        return await new Promise((resolve, reject) => {
            const subscription = EntityManager.getObservableRecord({
                entity: 'NAMESPACE',
                lk: namespace
            }).subscribe((val) => {
                if (val.data) {
                    NAMESPACE_URI[namespace] = val.data.url;
                    resolve(NAMESPACE_URI[namespace])
                    subscription?.unsubscribe();
                }
            })
        })
    }

    return NAMESPACE_URI[namespace];
}