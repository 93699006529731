
import * as Components from "antd";
import { FormikContextType, useFormikContext } from "formik";
import * as _ from "lodash";
import React, { useRef } from "react";
import { getEntityRecordViewLocale } from 'components/common/locale';
import { activeField } from 'components/common/recordUtils';
import { Field, FieldType, GeminiSchema } from "components/common/schema";
import { EntityRecordEditConfig, EntityRecordViewConfig, EntityReferenceConfigResolver, EntityReferenceRecordResolver, EntityReferenceSearchResolver } from "components/common/types";
import { fieldSorter, getConfigPath } from "./recordUtils";
import { TYPES_RESOLVERS } from "./typeConverters";
import { EditInnerState } from "./typeConverters/types";

import PARENT_FIELD_RESOLVER from './typeConverters/parentField';

function getFormInitialValues(record: any, schema: GeminiSchema): any {
    return getFormInitialValuesInner(record, schema.fields);
}

function getFormInitialValuesInner(record: any, fields: Field[]): any {
    let res = {}
    for (let f of fields) {
        if (f.type === FieldType.DICTIONARY) {
            res[f.name] = [];
            let count = 0;

            if (record) for (let key in record[f.name]) {
                res[f.name][count] = getFormInitialValuesInner(record[f.name][key], f.dict!.fields)
                res[f.name][count]["___key"] = key;
                count++;
            }
            continue
        }
        if (f.type === FieldType.OBJECT) {
            const objVal = record
            res[f.name] = record[f.name] && getFormInitialValuesInner(record[f.name], f.object!.fields)
            continue
        }
        if (f.type === FieldType.DATE && record[f.name]) {
            const av = record[f.name];
            if (Array.isArray(av)) {
                res[f.name] = new Date(av[0], av[1] - 1, av[2])
            }
            continue;
        }
        res[f.name] = record && record[f.name];
    }
    return res;
}

function fromValuesToRecord(record: any, schema: GeminiSchema, config?: EntityRecordEditConfig) {
    return formValuesToRecordInner(record, record, schema.fields, "", config);
}

function formValuesToRecordInner(value: any, originalRecord: any, fields: Field[], path: string, config?: EntityRecordEditConfig): any {
    let res = {}
    for (let f of fields) {

        // to not consider not active fields inside the form record
        const fieldName = path == "" ? f.name : path + "." + f.name;
        if (config && !activeField(originalRecord, fieldName, config)) {
            continue
        }

        if (f.type == FieldType.DICTIONARY) {
            res[f.name] = {};
            if (value) {
                for (let key in value[f.name]) {
                    if (value[f.name][key]) {
                        const innerPath = fieldName + "." + key;
                        res[f.name][value[f.name][key]["___key"]] = formValuesToRecordInner(value[f.name][key], originalRecord, f.dict!.fields, innerPath, config)
                    }
                }
            }
            continue
        }
        if (f.type === FieldType.OBJECT) {
            if (value[f.name]) {
                const innerPath = fieldName;
                res[f.name] = formValuesToRecordInner(value[f.name], originalRecord, f.object!.fields, innerPath, config)
            }
            continue
        }
        if (f.type === FieldType.DATE && value[f.name]) {
            const date = value[f.name] as Date;
            res[f.name] = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
            continue
        }
        res[f.name] = value && value[f.name];
    }
    return res;
}


const PageEditConverter = (props: {
    initRecord?: any, schema: GeminiSchema, config?: EntityRecordEditConfig,
    entityReferenceConfigResolver?: EntityReferenceConfigResolver
    entityReferenceRecordResolver?: EntityReferenceRecordResolver
    entityReferenceSearchResolver?: EntityReferenceSearchResolver

}) => {
    let context = useFormikContext()
    let innerState = useRef({
        arraySwitchOpe: {},
        collapseActiveKeys: {},
        jsonViewType: {},
        schema: props.schema,
        initRecord: props.initRecord,
        entityReferenceConfigResolver: props.entityReferenceConfigResolver,
        entityReferenceRecordResolver: props.entityReferenceRecordResolver,
        entityReferenceSearchResolver: props.entityReferenceSearchResolver
    } as EditInnerState)

    const fields = props.schema.fields;
    return <React.Fragment>
        <RecordConverterFieldOverObject recordAtLevel={context.values} fields={fields} formikContext={context} innerState={innerState.current} config={props.config} path={""}></RecordConverterFieldOverObject>
    </React.Fragment>

}


const RecordConverterField = (props: { value: any, field: Field, formikContext: FormikContextType<unknown>, innerState: EditInnerState, config?: EntityRecordEditConfig, path: string }) => {
    const { value, field, formikContext, innerState, config, path } = props


    const fieldName = field ? (path == "" ? field.name : path + "." + field.name) : "";
    // fieldName is the full path, with the index for the arrays (ex: field1.0.innerfield_1 to indicate field1: [{innerfield_1: something}])
    // the config.fields instead doesn't track the index os we need to remove it
    const fieldConfig = config?.fields?.[getConfigPath(fieldName)]
    const locale = getEntityRecordViewLocale(config);

    let EditFieldComponent = undefined;
    if (fieldConfig?.editValueRender) {
        EditFieldComponent = fieldConfig.editValueRender
    } else {

        // RESOLVER for the ALL THE FIELD SCHEMA TYPES - (strings, dates, bool, and so on..)
        let resolver = TYPES_RESOLVERS[field.type];

        // PARENT FIELD for TREE SCHEMA handled by itself
        if (innerState.schema.tree && innerState.schema.tree.enabled && field.name === innerState.schema.tree.parentField) {
            resolver = PARENT_FIELD_RESOLVER;
        }
        EditFieldComponent = resolver.EditFieldComponent;
    }

    // Actual Conversion of Field Type

    if (EditFieldComponent) {
        const props = { value, locale, field, fieldName, fieldConfig, innerState, path, config, formikContext }
        return <EditFieldComponent {...props} />
    }

    console.error(`Field ${fieldName} not handled`)
    return null;
}


function resolveCurrentElems(e: {
    lastElems: any[],
    lastElemType: 'DESCRIPTION' | 'COLLAPSE' | 'TABS' | undefined,
    result: any[]
}, count: number, config?: EntityRecordEditConfig) {

    if (e.lastElemType === 'DESCRIPTION') {
        const description = <Components.Descriptions key={`description_${count}`} column={config?.defaults?.descriptions?.column || 1} bordered={config?.defaults?.descriptions?.bordered || false} size={config?.defaults?.descriptions?.size || 'default'}>{e.lastElems}</Components.Descriptions>
        e.result.push(description)
    }

    if (e.lastElemType === 'TABS') {
        const tabs = <Components.Tabs key={`tabs_${count}`}>{e.lastElems}</Components.Tabs>
        e.result.push(tabs)
        e.result.push(<Components.Divider key={`divider_${count}`}></Components.Divider>)
    }

    return { lastElems: [], lastElemType: undefined, result: e.result }
}

const RecordConverterFieldOverObject = (props: { recordAtLevel: any, fields: Field[], formikContext: FormikContextType<unknown>, innerState: EditInnerState, config?: EntityRecordEditConfig, path: string }) => {
    const { recordAtLevel, fields, formikContext, innerState, config, path } = props

    var newFields = [...fields];
    newFields.sort(fieldSorter(path, fields, config))

    const locale = getEntityRecordViewLocale(config);

    let currentState: any = {
        lastElems: [],
        lastElemType: undefined,
        result: []
    }

    let count = 0;
    for (let innerF of newFields) {
        const fieldName = path == "" ? innerF.name : path + "." + innerF.name;

        const value = recordAtLevel && recordAtLevel[innerF.name];

        if (!activeField(formikContext.values, fieldName, config)) {
            continue
        }

        const key = innerF.name
        const label = innerF.displayName || key;

        const errorTag = _.get(formikContext.errors, fieldName);
        const touched = _.get(formikContext.touched, fieldName)
        const errorImportant = typeof errorTag === "string"; // for array or objects we need to go inside (so we have an array or an object as errorTag)
        const errorTouchedClasses = (errorImportant ? "gemini-field-error" : "") + " " + (touched ? "touched" : "")

        const finalClasses = errorTouchedClasses; // concatente others here if necessary

        if ([FieldType.INTEGER, FieldType.DOUBLE, FieldType.BOOL, FieldType.STRING, FieldType.ENUM, FieldType.SELECT,
        FieldType.B64_IMAGE, FieldType.ANY, FieldType.DATE, FieldType.ENTITY_REF, FieldType.GEOHASH_LOCATION].indexOf(innerF.type) >= 0) {
            if (currentState.lastElemType && currentState.lastElemType != 'DESCRIPTION')
                currentState = resolveCurrentElems(currentState, count, config);

            currentState.lastElemType = 'DESCRIPTION'
            currentState.lastElems.push(
                <Components.Descriptions.Item className={finalClasses} key={key} label={<b>{label}</b>}>
                    <div style={{ display: "block", width: "100%" }}>
                        <RecordConverterField value={value} field={innerF} formikContext={formikContext} innerState={innerState} config={config} path={path}></RecordConverterField>
                        {touched && errorImportant ? <div className="error-info">{locale.fields[errorTag]}</div> : null}
                    </div>
                </Components.Descriptions.Item>
            )
        }

        if ([FieldType.OBJECT, FieldType.DICTIONARY].indexOf(innerF.type) >= 0) {
            let sectionType = "COLLAPSE"; // code default
            sectionType = (innerF.type == FieldType.OBJECT && config && config.defaults && config.defaults.objectFields && config.defaults.objectFields.sectionType) || sectionType;
            if (config && config.fields) {
                const fieldConfig = config.fields[getConfigPath(fieldName)];
                if (fieldConfig && fieldConfig.sectionType)
                    sectionType = fieldConfig.sectionType;
            }

            if (sectionType == "COLLAPSE") {

                currentState = resolveCurrentElems(currentState, count, config);
                const configuredGhost = config?.defaults?.collapse?.ghost;
                const ghost = configuredGhost === undefined ? true : configuredGhost;

                currentState.result.push(
                    <Components.Collapse key={'collapse_' + fieldName} bordered={config?.defaults?.collapse?.bordered || false} ghost={ghost} defaultActiveKey={[key]}>

                        <Components.Collapse.Panel key={key} header={<b>{label}</b>} extra={null}>
                            <RecordConverterField value={value} field={innerF} formikContext={formikContext} innerState={innerState} config={config} path={path}></RecordConverterField>
                        </Components.Collapse.Panel>
                    </Components.Collapse>
                )
            }


            if (sectionType == "TAB") {
                if (currentState.lastElemType && currentState.lastElemType != 'TABS')
                    currentState = resolveCurrentElems(currentState, count, config);

                currentState.lastElemType = 'TABS'
                currentState.lastElems.push(
                    <Components.Tabs.TabPane key={key} tab={label}>
                        <RecordConverterField value={value} field={innerF} formikContext={formikContext} innerState={innerState} config={config} path={path}></RecordConverterField>
                    </Components.Tabs.TabPane>
                )
            }

        }

        if (innerF.type === FieldType.ARRAY) {
            const arrayType = innerF.array!.type

            let sectionType = config?.defaults?.arrayFields?.sectionType || "DESCRIPTION"
            if (sectionType == "DESCRIPTION") {

                if (currentState.lastElemType && currentState.lastElemType != 'DESCRIPTION')
                    currentState = resolveCurrentElems(currentState, count, config);

                currentState.lastElemType = 'DESCRIPTION'
                currentState.lastElems.push(
                    <Components.Descriptions.Item className={finalClasses} key={key} label={<b>{label}</b>}>
                        <div style={{ display: "block", width: "100%" }}>
                            <RecordConverterField value={value} field={innerF} formikContext={formikContext} innerState={innerState} config={config} path={path}></RecordConverterField>

                            {arrayType != FieldType.OBJECT && touched && errorImportant ? <div className="error-info">{locale.fields[errorTag]}</div> : null}
                        </div>
                    </Components.Descriptions.Item>)
            }
        }

        count += 1;
    }

    currentState = resolveCurrentElems(currentState, count, config);
    const lastElemKey: string = currentState.result[currentState.result.length - 1].key;
    if (lastElemKey.startsWith("divider"))
        currentState.result.pop()
    return <React.Fragment>
        {currentState.result}
    </React.Fragment>

}

export { PageEditConverter, getFormInitialValues, fromValuesToRecord, RecordConverterFieldOverObject, RecordConverterField };
