import React from "react";
import { InputNumber } from "antd";
import { FastField, FieldProps } from "formik";
import { EditFieldSpec, FieldSpec, ViewFieldSpec } from "./types";



export const EditFieldComponent = (props: EditFieldSpec) => {
    const { fieldName } = props
    return <FastField name={fieldName}>
        {({ field, form, ...props }: FieldProps) => {
            const { value, ...propsNotValue } = field;
            return <InputNumber {...propsNotValue} {...props} defaultValue={value} step={0.01}
                onChange={(value) => { form.setFieldValue(field.name, value) }}
            />
        }}
    </FastField>

}

export const ViewFieldComponent = (props: ViewFieldSpec) => {
    const { value } = props
    return value ?? null;
}

export default { EditFieldComponent, ViewFieldComponent } as FieldSpec