
import { deleteDB, IDBPDatabase, openDB } from 'idb';
import AuthService from './auth.service';
import Axios from "axios";
import { DEFAULT_NAMESPACE, getDataURI, getSchemaURI } from './uriUtils';


/* const cache = new LRU<string, any>({
    max: 500,
    updateAgeOnGet: true
})

const LRUCacheService = {

    get(key: string): any {
        return cache.get(key);
    },

    set(key: string, value: any) {
        cache.set(key, value)
    },

    clean(key: string) {
        cache.keys().filter(k => key.indexOf(k) > -1).forEach(k => cache.del(k))
    }

} */

export interface CachedContent {
    value: any,
    date: Date
}

export interface SummaryResp {
    data: {
        schema: {
            hash: string
        },
        entities: SummaryRespEntities
    }
}

export interface SummaryRespEntities {
    [key: string]: {
        lastUpdateTimeUnix: number
        lastDeleteTimeUnix: number
        lastCreateTimeUnix: number
    }
}


class RecordsByURLCacheClass {

       
    /* private readonly db = openDB('RecordsByURLCache', 2, {
        async upgrade(db, oldVersion) {
            if(oldVersion < 2) {
                await deleteDB('RecordsByURLCache')
            }
            db.createObjectStore('keyval');
        },
    }); */

    private db: Promise<IDBPDatabase<any>> | undefined;
    
    /*constructor() {
        this.db =  openDB('RecordsByURLCache').then(db => {
            if(db.version < 2) {
                 db.close();
                return db.
            }
        })
    } */

    async getDB() {
        if(this.db)
            return this.db;

        const innerDb = await openDB('RecordsByURLCache')
        if(innerDb.version < 2) {
            innerDb.close();
            await deleteDB('RecordsByURLCache')
        }
        this.db = openDB('RecordsByURLCache', 2, {
            async upgrade(db) {
                db.createObjectStore('keyval');
            },
        });
        return this.db;
    }

    async getWithTime(key: string ): Promise<CachedContent> {
        return (await this.getDB()).get('keyval', key)
    }

    async get(key: string): Promise<any> {
        const cachedValue: CachedContent = await (await this.getDB()).get('keyval', key)
        if(!cachedValue) 
            return undefined;
        return cachedValue.value;
    }

    async set(key: string, value: any): Promise<any> {
        return (await this.getDB()).put('keyval', {value, date: new Date()} , key);
    }

    async updateOnlyCacheValue(key: string, value: any): Promise<any> {
        const cachedValue = await this.getWithTime(key)
        return (await this.getDB()).put('keyval', {value, date: cachedValue.date} , key);
    }

    async setIfExist(key: string, value: any): Promise<boolean> {
        if (this.getWithTime(key)) {
            await this.set(key, value);
            return true;
        }
        return false;
    }
}

class CacheManagerClass {
    private readonly summaries = new Map<string, {uri: string, resp: SummaryResp, date: Date}>();


    async getNamespaceSummary(namespace: { name: string, uri: string }) {
        const accessToken = AuthService.getAccessToken()
        let httpConfig = { headers: { 'Authorization': 'Bearer ' + accessToken } }
        const summaryURI = `${namespace.uri}/summary`
        const resp = await Axios.get<SummaryResp>(summaryURI, httpConfig)
        this.summaries.set(namespace.name, {uri: namespace.uri, resp: resp.data, date: new Date()})

        // TODO check ??
        return resp.data.data;
    }

    async getFromCache(cacheUri: string) {
        const cacheData = await RecordsByURLCache.get(cacheUri);
        if (cacheData) {
            return {
                state: 'CACHED',
                data: cacheData.data,
                lastUpdate: cacheData.meta?.lastUpdateTimeUnix ? new Date(cacheData.meta.lastUpdateTimeUnix) : undefined,
            }
        }
        return undefined;
    }

    async getSchemaIfUpdated(entityReq: {namespace: string, entity: string}, options?: {}) {
        const summaryCache = this.summaries.get(entityReq.namespace ?? DEFAULT_NAMESPACE);
        if(summaryCache){
            const hash = summaryCache.resp.data.schema.hash;
            
            const cacheKey = await getSchemaURI(entityReq);
            const cacheContent = await RecordsByURLCache.get(cacheKey);

            if (cacheContent && cacheContent.data && cacheContent.data.schemaHash !== hash) {
                return undefined;
            }

            return cacheContent;
        }
        return undefined;
    }

    async getDataIfUpdated(entityReq: {namespace: string, entity: string}, options?: {}) {
        const summaryCache = this.summaries.get(entityReq.namespace ?? DEFAULT_NAMESPACE);
        if(summaryCache){
            const entities = summaryCache.resp.data.entities;

            const targetUpdateValues = entities && entities[entityReq.entity.toUpperCase()]
            if (targetUpdateValues) {

                const cacheKey = await this.getCacheKey(entityReq);
                const cacheContent = await RecordsByURLCache.getWithTime(cacheKey);

                const targetTime = Math.max(targetUpdateValues.lastCreateTimeUnix, targetUpdateValues.lastUpdateTimeUnix, targetUpdateValues.lastDeleteTimeUnix);

                if (!cacheContent || (cacheContent && cacheContent.date < new Date(targetTime))) {
                    return undefined;
                }

                return cacheContent.value;
            }
        }
        return undefined;
    }

    private async getCacheKey(req: any) {
        const dataUri = await getDataURI(req);
        if (!req.filter)
            return dataUri;
    
        return dataUri + "_" + JSON.stringify(req.filter);
    }
}



export const RecordsByURLCache = new RecordsByURLCacheClass();
export const CacheManager = new CacheManagerClass();

const toExp = { RecordsByURLCache, CacheManager }
export default toExp;