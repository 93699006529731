import { FastField, FieldProps } from 'formik';
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { getRecordLk, getRecordReferenceDisplayValue } from "../../common/recordUtils";
import { EntityRef, Field, GeminiSchema } from "../../common/schema";
import { EntityRecordViewConfig, EntityReferenceConfigResolver, EntityReferenceRecordResolver, EntityViewConfig } from "../../common/types";
import { DebounceSelect } from './commons/debounceSelect';
import { EditFieldSpec, EditInnerState, FieldSpec, ViewFieldSpec } from './types';

export async function entityRef_Fetch(searchValue: string, entityRef: EntityRef, innerState: EditInnerState) {
    if (innerState.entityReferenceSearchResolver) {
        const searchRes = await innerState.entityReferenceSearchResolver(searchValue, entityRef.entity);

        if (innerState.entityReferenceConfigResolver) {
            const refConfig = await innerState.entityReferenceConfigResolver(entityRef.entity);
            let fields = refConfig.schema?.refDisplayFields;
            const separator = refConfig.schema?.refDisplaySeparator ?? " - ";

            if (!fields) {
                if (searchRes.lks) {
                    return searchRes.lks.map(r => ({ label: r, value: r }))
                }

                if (searchRes.records) {
                    return searchRes.records.map(r => getRecordLk(r, refConfig.schema!))
                        .map(r => ({ label: r, value: r }))
                }

            } else {

                if (searchRes.records) {
                    return searchRes.records.map(r => ({ label: _.at(r, fields!).join(separator), value: getRecordLk(r, refConfig.schema!) }))
                }

                if (searchRes.lks && innerState.entityReferenceRecordResolver) {
                    const res = [];
                    for (let el of searchRes.lks) {
                        const r = await innerState.entityReferenceRecordResolver(entityRef.entity, el)
                        res.push({ label: _.at(r, fields!).join(separator), value: getRecordLk(r, refConfig.schema!) })

                    }
                    return res;
                }

            }

        } else {
            if (searchRes.lks) {
                return searchRes.lks.map(r => ({ label: r, value: r }))
            }
        }
    }

    return [];
}


export const EntityRefDetailsConverter = ({ path, field, value, config, entityReferenceConfigResolver, entityReferenceRecordResolver }:
    {
        field: Field, path: string, value: string, config?: EntityRecordViewConfig
        entityReferenceConfigResolver?: EntityReferenceConfigResolver,
        entityReferenceRecordResolver?: EntityReferenceRecordResolver
    }) => {
    path
    config
    const [refConfiguration, setConfiguration] = useState<{ loaded: boolean, schema?: GeminiSchema, config?: EntityViewConfig, value?: any }>({ loaded: false });

    useEffect(() => {
        getRecordReferenceDisplayValue(field, value, setConfiguration, entityReferenceConfigResolver, entityReferenceRecordResolver)
    }, [value])

    if (!refConfiguration.loaded)
        return null;

    // not need to resolve object we can use "LK"
    return <>{refConfiguration.value}</>;
}



export const EditFieldComponent = (props: EditFieldSpec) => {
    const { fieldName, field, innerState } = props


    return <FastField name={fieldName}>
        {(fp: FieldProps) => {
            const { value, ...propsNotValue } = fp.field;
            return <DebounceSelect
                style={{ width: "100%" }} {...propsNotValue} /*onBlur={(e) => { fp.field.onBlur(e), fp.form.setFieldTouched(fp.field.name, true, true) }} */
                value={fp.field.value} onChange={(selected) => { fp.form.setFieldValue(fp.field.name, selected) }}
                allowClear={!field.required}
                showSearch
                showArrow={false}
                fetchOptions={async (searchValue: string) => {
                    return entityRef_Fetch(searchValue, field.entityRef!, innerState);
                }}
            />
        }}
    </FastField>

}


export const ViewFieldComponent = (props: ViewFieldSpec) => {
    const { value, field, path, config, innerState } = props
    return <EntityRefDetailsConverter value={value} field={field} path={path} config={config}
        entityReferenceConfigResolver={innerState.entityReferenceConfigResolver}
        entityReferenceRecordResolver={innerState.entityReferenceRecordResolver}
    />
}

export default { EditFieldComponent, ViewFieldComponent } as FieldSpec