import { TreeSelect } from "antd";
import { FastField, FieldProps } from "formik";
import React, { useEffect, useState } from "react";
import { convertRecordsToTree } from "../../common/entityUtils";
import { EditFieldSpec, FieldSpec } from "./types";


const TreeRefComponent = ({ fieldName, value, fp, innerState }: EditFieldSpec & { fp: FieldProps }) => {
    const [treeData, setTreeData] = useState(undefined)

    // TODO better use of treeselect, with debounce and real search

    useEffect(() => {
        async function getData() {
            const found = await innerState.entityReferenceSearchResolver!("", innerState.schema.name) || []
            const td = found.records;
            const totTree = convertRecordsToTree(td, innerState.schema.lk[0], innerState.schema.tree!.parentField)
            const toSelectTree = (obj: any, disabled: boolean = false): {} | [] | undefined => {
                if (Array.isArray(obj))
                    return obj.map(o => toSelectTree(o, disabled))

                if (!obj)
                    return undefined;

                disabled = disabled || (obj && innerState.initRecord && obj[innerState.schema.lk[0]] === innerState.initRecord[innerState.schema.lk[0]])
                let res = {
                    value: obj[innerState.schema.lk[0]],
                    title: obj[innerState.schema.lk[0]],
                    disabled: disabled
                }
                if (obj.children)
                    res['children'] = toSelectTree(obj.children, disabled);

                return res
            }
            const tda = toSelectTree(totTree)
            setTreeData(tda as any)
        }

        if (!treeData) {
            getData()
        }
    }, [treeData])



    const onChange = (value: any) => {
        fp.form.setFieldValue(fieldName, value)
        setTreeData(undefined)
    }

    return <TreeSelect
        // treeDataSimpleMode
        allowClear
        style={{ width: '100%' }}
        value={value}
        //dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeData={treeData}
        placeholder="Please select"
        treeDefaultExpandAll
        onChange={onChange}
    />

}

export const EditFieldComponent = (props: EditFieldSpec) => {
    const { fieldName, value, innerState } = props

    if (innerState.entityReferenceSearchResolver) {
        return <FastField name={fieldName} children={(fp: FieldProps) => <TreeRefComponent {...props} fp={fp} />} />
    }

    return value;
}

export default { EditFieldComponent } as FieldSpec

