import * as _ from "lodash";
import React from "react";
import { Field, FieldType } from "../../common/schema";
import { RecordConverterField } from "../recordEditConverter";
import { EditFieldSpec, FieldSpec, ViewFieldSpec } from "./types";

export const EditFieldComponent = (props: EditFieldSpec) => {
    const { field, formikContext, path } = props

    let extractedType;
    if (field.any && field.any.typeField) {
        const parentPath = _.get(formikContext.values, path);
        if (parentPath && parentPath[field.any!.typeField])
            extractedType = parentPath[field.any!.typeField]
        else
            extractedType = _.get(formikContext.values, field.any!.typeField) ?? "STRING";
    }
    const fieldType = FieldType[extractedType];
    const newField: Field = {...field, type: fieldType}
    return <RecordConverterField {...props} field={newField}></RecordConverterField>

}

export const ViewFieldComponent = (props: ViewFieldSpec) => {
    const { value } = props
    return value ?? "";
}


export default { EditFieldComponent, ViewFieldComponent } as FieldSpec