import { AimOutlined, DeleteOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Autocomplete, GoogleMap, Marker } from '@react-google-maps/api';
import * as Components from "antd";
import { FastField, FieldProps } from "formik";
import * as geofire from 'geofire-common';
import React, { useRef, useState } from "react";
import { EditFieldSpec, FieldSpec, ViewFieldSpec } from './types';

const { Text } = Components.Typography;


const EditComponent = ({ fieldProps, locale }: { fieldProps: FieldProps } & EditFieldSpec) => {
    const { value, ...propsNotValue } = fieldProps.field;

    const inputRef = useRef<HTMLInputElement>(null);
    const [autocompleteRef, setAutocompleteRef] = useState<google.maps.places.Autocomplete | null>(null);

    const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
    const [center, setCenter] = useState(value ? value : {
        lat: 41.902782,
        lng: 12.496366
    })
    const [zoom, setZoom] = useState(value ? 13 : 5)
    const [marker, setMarker] = useState<google.maps.LatLng | null>(value)
    const [mapMode, setMapMode] = useState("pick")

    return <>
        <Components.Radio.Group defaultValue={"pick"} size="small" onChange={(e) => setMapMode(e.target.value)}>
            <Components.Radio.Button value="pick"><AimOutlined /> {locale.types.geohash_location.pick}</Components.Radio.Button>
            <Components.Radio.Button value="location"><EnvironmentOutlined /> {locale.types.geohash_location.byLocation}</Components.Radio.Button>
        </Components.Radio.Group>

        {marker ? <Components.Button size="small" type="link" danger key="delete" onClick={(event) => {
            setMarker(null);
            fieldProps.form.setFieldValue(fieldProps.field.name, null)
            event.stopPropagation();
            if (inputRef.current)
                inputRef.current.value = "";
        }}><DeleteOutlined />Delete</Components.Button> : null}

        <div style={{ marginTop: "5px" }}>
            <Text type="secondary">Lat: {(value?.lat ?? "Ø") + " - "}</Text>
            <Text type="secondary">Lng: {(value?.lng ?? "Ø")}</Text>
        </div>
        <div>
            <Text type="secondary">Hash: {(value?.geohash ?? "Ø") + ""}</Text>
        </div>

        {mapMode === "location" ?
            <Autocomplete
                restrictions={{ country: ["it"] }}
                onLoad={(autocomplete: any) => { setAutocompleteRef(autocomplete) }}
                onPlaceChanged={() => {
                    const place = autocompleteRef!.getPlace();
                    const location = place.geometry?.location
                    if (location) {
                        setMarker(location)
                        const hash = geofire.geohashForLocation([location.lat(), location.lng()], 10);
                        const value = { lat: location.lat(), lng: location.lng(), geohash: hash, googlePlaceId: place.place_id }
                        fieldProps.form.setFieldValue(fieldProps.field.name, value)
                    }

                    const bounds = new window.google.maps.LatLngBounds();
                    if (place.geometry?.viewport) {
                        // Only geocodes have viewport.
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place?.geometry?.location!);
                    }
                    mapRef!.fitBounds(bounds);

                }}
            >
                <div style={{ display: "block", width: "100%" }}>
                    <input ref={inputRef} className="ant-input" placeholder={locale.types.geohash_location.locationPlaceholder} />
                </div>
                {/*<Components.Input placeholder={locale.types.geohash_location.locationPlaceholder} />*/}
            </Autocomplete> : null}

        <div style={{ marginTop: "5px" }}>
            <GoogleMap
                mapContainerStyle={{ width: "100%", height: "300px" }}
                center={center}
                zoom={zoom}
                onLoad={(map: any) => { setMapRef(map) }}
                //onCenterChanged={() => setCenter(mapRef!.getCenter().toJSON())}
                onClick={(e) => {

                    if (mapMode === "pick" && e.latLng) {
                        setMarker(e.latLng)
                        const hash = geofire.geohashForLocation([e.latLng.lat(), e.latLng.lng()], 10);
                        const value = { lat: e.latLng.lat(), lng: e.latLng.lng(), geohash: hash }
                        fieldProps.form.setFieldValue(fieldProps.field.name, value)
                    }
                }}
                options={
                    {
                        draggableCursor: null,
                        draggingCursor: null,
                        streetViewControl: false,
                        fullscreenControl: false,
                        rotateControl: false
                    }
                }
            // onLoad={onLoad}
            // onUnmount={onUnmount}
            >
                {marker ? <Marker position={marker} /> : null}
            </GoogleMap>
        </div>
    </>
}

export const EditFieldComponent = (props: EditFieldSpec) => {

    return <FastField name={props.fieldName} >
        {(fieldProps: FieldProps) => <EditComponent fieldProps={fieldProps} {...props} />}
    </FastField>
}

export const ViewFieldComponent = (_props: ViewFieldSpec) => {
   return null;
}

export default { EditFieldComponent, ViewFieldComponent } as FieldSpec