import { EntityView, GeminiSchema, GetStartegies, recordUtils } from '@gemini-projects/gemini-react-entity-lib';
import { EntityReferenceConfigResolver, EntityReferenceSearchResolver, RecordOperation } from '@gemini-projects/gemini-react-entity-lib/dist/components/common/types';
import { Skeleton } from 'antd';
import { useAllRecord, useSchema, useSearch } from 'hooks/records.hooks';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import entityManagerService from 'services/entityManager.service';
import { DEFAULT_NAMESPACE } from 'services/uriUtils';
import defaultNamespaceClientConfig from './defaultNamespaceClientConfig';

// const STATUS_OLD_MINUTES_DELAY = Number(process.env['REACT_APP_STATUS_OLD_MINUTES_DELAY']);

export const NSEntityView = (props: {
    entity: string, lk?: string, namespace?: string, recordsActions?: {
        strategy: 'SUBSTITUTE' | 'APPEND',
        actions: (props) => any[]
    }, entityActions?: {
        strategy: 'SUBSTITUTE' | 'APPEND',
        actions: (props) => any[]
    }
}) => {
    const { namespace, entity, recordsActions, entityActions } = props;


    // the schema definition for the records (accordingly to Gemini types)
    const [schemaData, schemaReload] = useSchema(props)
    const schema = schemaData.data?.entity;

    // contains Actual DATA and records
    const [recordData, recordDataReload] = useAllRecord(props)
    const records = recordData.data;

    // contain configuration to draw components (tables/forms and so on)
    const [entityConfigData, configReload] = useSearch({
        entity: "DefaultEntityConfig",
        filter: {
            "entity": entity.toUpperCase()
        }
    })
    const configRecords = entityConfigData.data;
    let entityViewConfig: any = null;
    if (configRecords) {
        configRecords.forEach((c: any) => {
            if ((typeof c.datastore === "string" && c.datastore.length > 0) || !entityViewConfig) {
                entityViewConfig = c;
            }
        })
    }

    // understand and update the status of the records (may be updated, checking or old)
    const [recordsStatus, setRecordStatus] = useState<any>(undefined);
    //const [oldStatusConfig, setStatusConfig] = useState<any>(undefined);
    //const intervalRef = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (recordData.checking !== undefined || schemaData.checking !== undefined || entityConfigData.checking !== undefined) {
            if (recordData.checking || schemaData.checking || entityConfigData.checking) {
                setRecordStatus("CHECKING")
            } else {
                setRecordStatus("CHECKED");
            }
        }
    }, [recordData, schemaData, entityConfigData]);

    /* useEffect(() => {
        if (recordsStatus === "CHECKED") {
            const lastCheckMillis = Math.max(recordData.lastCheck?.getTime() ?? 0, schemaData.lastCheck?.getTime() ?? 0, entityConfigData.lastCheck?.getTime() ?? 0)
            console.log(lastCheckMillis);
            intervalRef.current = setInterval(() => {
                const now = new Date().getTime()
                const millis =  now - lastCheckMillis
                const toMinutes = millis / 1000 / 60
                if (toMinutes > STATUS_OLD_MINUTES_DELAY) {
                    setRecordStatus("OLD");
                    setStatusConfig({
                        value: Math.ceil(toMinutes),
                        unit: "minutes"
                    })
                }
            }, 60000)

        }

        if (recordsStatus === "CHECKING" && intervalRef.current) {
            clearInterval(intervalRef.current)
        }
    }, [recordsStatus]) */

    // for the component destroy
    /*useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
    }, [])*/


    const resolverSchemaCache = useRef(new Map<string, { schema: BehaviorSubject<GeminiSchema | undefined> }>());


    if ((!configRecords || !schema || !records))
        return <div style={{ margin: 24 }}><Skeleton active /></div>


    const onRecordCreate: RecordOperation = async (context, ok, error) => {
        try {
            // const resp = await auth(axios.post(actionUrl, { data: newRec }, httpConfig))
            await entityManagerService.newRecord({ ...props, record: context.record });
            return ok();
        } catch (e) {
            console.error(e)
            error()
        }

    }

    const onRecordDelete: RecordOperation = async (context, ok, error) => {
        try {
            await entityManagerService.delete({ ...props, record: context.record, lk: context.lk });
            ok()
        } catch (e) {
            console.error(e)
            error()
        }
    }

    const onRecordUpdate: RecordOperation = async (context, ok, error) => {
        try {
            await entityManagerService.update({ ...props, lk: context.lk!, record: context.record });
            ok()
        } catch (e) {
            console.error(e)
            error()
        }
    }

    const entityReferenceConfigResolver: EntityReferenceConfigResolver = async (entity: string) => {
        // TODO ADD CONFIG and CACHE

        // ref schema cache of course....
        if (!resolverSchemaCache.current.has(entity)) {
            const subject = new BehaviorSubject<GeminiSchema | undefined>(undefined);
            resolverSchemaCache.current.set(entity, { schema: subject })
            const schemaDT = await entityManagerService.getSchema({ entity, namespace });
            const schema = schemaDT.data!.entity;
            subject.next(schema)
        }

        return await new Promise((resolve, reject) => {
            resolverSchemaCache.current.get(entity)!.schema.subscribe((schema) => {
                if (schema) {
                    resolve({ schema })
                }
            })
        })

    }

    const entityReferenceSearchResolver: EntityReferenceSearchResolver = async (search: string, entity: string) => {
        const schemaDT = await entityManagerService.getSchema({ entity, namespace });
        const schema = schemaDT.data!.entity as GeminiSchema;

        if (schema.getStrategies?.includes(GetStartegies.ALL)) {
            const recordsDT = await entityManagerService.getAll({ entity, namespace });

            const array = recordsDT!.data ?? [] as Array<any>;
            if (search === "" || search === undefined)
                return { records: array }
            const filtered = array.filter((e: any) => {
                return recordUtils.getRecordLk(e, schema).toLowerCase().includes(search);
            })

            return { records: filtered }
        }
        return { records: [] }
    }


    const onReload = async () => {
        recordDataReload()
        schemaReload()
        configReload()
        resolverSchemaCache.current.clear()
    }

    const defaultTablePagination = localStorage.getItem("__gbo_pagination_" + JSON.stringify({ namespace, entity }));
    const onTableChange: any = async (pagination: any) => {
        const { pageSize } = pagination
        localStorage.setItem("__gbo_pagination_" + JSON.stringify({ namespace, entity }), JSON.stringify({ defaultPageSize: pageSize, showSizeChanger: true }))
    }

    const defaultTableSize: any = localStorage.getItem("__gbo_tablesize_" + JSON.stringify({ namespace, entity })) ?? undefined;
    const onTableSizeChange = async (size: any) => {
        localStorage.setItem("__gbo_tablesize_" + JSON.stringify({ namespace, entity }), size)
    }

    const defaultTableViewColumns: any = localStorage.getItem("__gbo_tablecheckedfields_" + JSON.stringify({ namespace, entity })) ?? undefined;
    const onTableViewColumns = async (checkedFields: any) => {
        localStorage.setItem("__gbo_tablecheckedfields_" + JSON.stringify({ namespace, entity }), checkedFields)
    }

    let actualConfig = {}
    if (entityViewConfig && entityViewConfig.config && typeof entityViewConfig.config === "string") {
        actualConfig = JSON.parse(entityViewConfig.config);
    }
    if (!namespace || namespace === DEFAULT_NAMESPACE) {
        const clientConfig = defaultNamespaceClientConfig[entity.toUpperCase()];
        actualConfig = _.merge(clientConfig, actualConfig)
    }

    return <EntityView key={JSON.stringify({ namespace, entity })} schema={schema} records={records} config={actualConfig} recordsStatus={recordsStatus} /*oldStatusConfig={oldStatusConfig}*/
        onRecordCreate={onRecordCreate} onRecordUpdate={onRecordUpdate} onRecordDelete={onRecordDelete}
        onTableChange={onTableChange} defaultTablePagination={defaultTablePagination ? JSON.parse(defaultTablePagination) : { showSizeChanger: true }}
        onReload={onReload} visibleStatus={['CHECKING']} onTableSizeChange={onTableSizeChange} defaultTableSize={defaultTableSize}
        entityReferenceConfigResolver={entityReferenceConfigResolver} entityReferenceSearchResolver={entityReferenceSearchResolver}
        defaultTableViewColumns={defaultTableViewColumns} onTableViewColumnsChange={onTableViewColumns}
        recordActionsStrategy={recordsActions?.strategy} recordActions={recordsActions?.actions}
        entityActionsStrategy={entityActions?.strategy} entityActions={entityActions?.actions}
    />

}