import { useEffect, useState } from "react";
import { EntityManager, ObservableArrayRecords, ObservableData, ObservableRecord, ObservableSchema, SearchRequest } from "../services/entityManager.service";


export function useRecord(req: { entity: string, lk: string, namespace?: string }): [ObservableData<any | undefined>, () => void] {

    const [retVal, setState] = useState({ state: 'LOADING' } as ObservableRecord)
    const observable = EntityManager.getObservableRecord(req);
    const reload = () => {
        observable.next({ ...observable.getValue(), checking: true });
        EntityManager.checkUpdates(req);
    }

    useEffect(() => {
        const subscription = observable.subscribe(setState)

        return function cleanup() {
            subscription?.unsubscribe();
        }
    }, [req, observable])

    return [retVal, reload]
}


export function useAllRecord(req: { entity: string, namespace?: string }): [ObservableArrayRecords, () => void] {

    const [retVal, setState] = useState({ state: 'LOADING' } as ObservableArrayRecords)
    const observable = EntityManager.getALLObservableRecords(req);
    const reload = () => {
        observable.next({ ...observable.getValue(), checking: true });
        EntityManager.checkUpdates(req);
    }

    useEffect(() => {
        const subscription = observable.subscribe(setState)

        return function cleanup() {
            subscription?.unsubscribe();
        }
    }, [req, observable])

    return [retVal, reload]
}

export function useSchema(req: { entity: string, lk?: string, namespace?: string }): [ObservableSchema, () => void] {
    const [schema, setSchema] = useState({ state: 'LOADING' } as ObservableSchema)
    const observable = EntityManager.getObservableSchema(req)
    const reload = () => {
        observable.next({ ...observable.getValue(), checking: true });
        EntityManager.checkUpdates(req);
    }

    useEffect(() => {

        const subscription = EntityManager.getObservableSchema(req).subscribe(setSchema)

        return function cleanup() {
            subscription?.unsubscribe();
        }
    }, [req]);

    return [schema, reload];
}


export function useSearch(req: SearchRequest): [ObservableArrayRecords, () => void] {
    const [retVal, setState] = useState({ state: 'LOADING' } as ObservableArrayRecords)
    const observable = EntityManager.getObservableSearch(req)
    const reload = () => {
        observable.next({ ...observable.getValue(), checking: true });
        EntityManager.checkUpdates(req);
    }
    useEffect(() => {
        const subscription = observable.subscribe(setState)

        return function cleanup() {
            subscription?.unsubscribe();
        }
    }, [req, observable])

    return [retVal, reload]
}