import DatePicker from '../../times/DatePicker';
import dayjs from 'dayjs';
import { FastField, FieldProps } from "formik";
import React from "react";
import { EditFieldSpec, FieldSpec, ViewFieldSpec } from "./types";



export const EditFieldComponent = (props: EditFieldSpec) => {
    const { fieldName, locale, field } = props
    const required = field.required;

    return <FastField name={fieldName}>
        {({ field, form, ...props }: FieldProps) => {
            const { value, ...propsNotValue } = field
            const dfV = dayjs(value as any);

            return <DatePicker {...props} {...propsNotValue} defaultValue={dfV} format={locale.types.date.format}
                allowClear={!required}
                onChange={(value) => { form.setFieldValue(field.name, value ? value.toDate() : null) }} />
        }}

    </FastField>

}

export const ViewFieldComponent = (props: ViewFieldSpec) => {
    const { value, locale } = props
    if (Array.isArray(value)) {
        return dayjs(value as any).format(locale.types.date.format)
    }
    return value ?? null;
}


export default { EditFieldComponent, ViewFieldComponent } as FieldSpec