import React from "react";
import { recordConverterFieldOverObject } from "../recordDetailsConverters";
import { RecordConverterFieldOverObject } from "../recordEditConverter";
import { EditFieldSpec, FieldSpec, ViewFieldSpec } from "./types";

export const EditFieldComponent = (props: EditFieldSpec) => {
    const { value, fieldName, fieldConfig, path, field, formikContext, innerState, config } = props
    const innerPath = field ? (path === "" ? field.name : path + "." + field.name) : "";
    return <RecordConverterFieldOverObject recordAtLevel={value} fields={field.object!.fields} formikContext={formikContext} innerState={innerState} config={config} path={innerPath}></RecordConverterFieldOverObject>

}

export const ViewFieldComponent = (props: ViewFieldSpec) => {
    const { value, field, path, innerState, config } = props
    const fields = field.object!.fields;
    const innerPath = field ? (path === "" ? field.name : path + "." + field.name) : "";
    return recordConverterFieldOverObject(value, fields, innerState, config, innerPath)
}


export default { EditFieldComponent, ViewFieldComponent } as FieldSpec