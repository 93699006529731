import { Alert, AutoComplete, Col, Divider, Input, Row, Skeleton } from 'antd'
import { NSEntityView } from 'components/NamespaceEntities/NsEntityView'
import { useEffect, useRef, useState } from 'react'
import * as React from 'react'
import entityManagerService from 'services/entityManager.service'




export const AllEntityView = () => {

    const [isLoading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [selectedOption, setSelected] = useState(null)
    const options = useRef([])


    useEffect(() => {
        async function fetchData() {
            if (isLoading) {
                // const dataSources = entityManagerService.getAll('DATASOURCE');
                // TODO add datasources
                try {
                    const defaultSchemas = await entityManagerService.getSchemas()
                    for (const ds of defaultSchemas) {
                        options.current.push({ label: ds.displayName || ds.name, value: ds.name, schema: ds })
                    }
                } catch (e) {
                }
                setLoading(false)
                if (options.current.length === 0) {
                    setError(true)
                }
            }
        }
        fetchData()
    }, [isLoading])

    if (error) {
        return <Alert
            message="Error"
            description="Unable to show the Configuration."
            type="error"
            showIcon
        />
    }

    if (isLoading)
        return <Skeleton active></Skeleton>


    const onSelect = (value, option) => {
        setSelected(option)
    }

    return <div style={{ width: "100%" }}>
        <Row>
            <Col span={24}>
                <AutoComplete style={{ width: "100%" }} options={options.current} filterOption={true} onSelect={onSelect}>
                    <Input.Search size="large" placeholder="Select entity" />
                </AutoComplete>
            </Col>
        </Row>
        {selectedOption ? (
            <React.Fragment>
                <Divider></Divider>
                <Row >
                    <Col span={24}>
                        <NSEntityView entity={selectedOption.schema.name} key={selectedOption.schema.name}></NSEntityView>
                    </Col>
                </Row>
            </React.Fragment>
        ) : null}

    </div>



}