import { FieldType } from "../../common/schema";
import { FieldSpec } from "./types";

import * as STRING from './string';
import * as GEOHASH_LOCATION from './geoHashLocation';
import * as DOUBLE from './double';
import * as INTEGER from './integer';
import * as BOOL from './bool';
import * as ENUM from './enum';
import * as DATE from './date';
import * as SELECT from './select';
import * as ENTITY_REF from './entityRef';
import * as B64_IMAGE from './b64Image';
import * as DICTIONARY from './dictionary';
import * as ANY from './any';
import * as ARRAY from './array';
import * as OBJECT from './object';


// TODO when we have all  => const TYPES_RESOLVERS: {[key in FieldType]: FieldSpec} = {
export const TYPES_RESOLVERS: { [key in string]: FieldSpec } = {
    [FieldType.GEOHASH_LOCATION]: GEOHASH_LOCATION,
    [FieldType.STRING]: STRING,
    [FieldType.DOUBLE]: DOUBLE,
    [FieldType.INTEGER]: INTEGER,
    [FieldType.BOOL]: BOOL,
    [FieldType.ENUM]: ENUM,
    [FieldType.DATE]: DATE,
    [FieldType.SELECT]: SELECT,
    [FieldType.ENTITY_REF]: ENTITY_REF,
    [FieldType.B64_IMAGE]: B64_IMAGE,
    [FieldType.DICTIONARY]: DICTIONARY,
    [FieldType.ANY]: ANY,
    [FieldType.ARRAY]: ARRAY,
    [FieldType.OBJECT]: OBJECT
}