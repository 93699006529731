import * as Icons from '@ant-design/icons';
import {
  HomeOutlined, UserOutlined
} from '@ant-design/icons';
import { entityUtils } from "@gemini-projects/gemini-react-entity-lib";
import { LoadScript } from '@react-google-maps/api';
import { Breadcrumb, Button, Dropdown, Layout, Menu, PageHeader, Result, Skeleton, Typography } from "antd";
import Avatar from 'antd/lib/avatar/avatar';
import SubMenu from 'antd/lib/menu/SubMenu';
import { BasicHome } from 'components/Home/BasicHome';
import { NotFound } from 'components/NotFound/NotFound';
import { NSEntityView } from "components/NamespaceEntities/NsEntityView";
import { useAllRecord, useRecord } from 'hooks/records.hooks';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { NavLink, Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { AuthService, useAthentication } from "../../services/auth.service";
import { Settings } from "../Settings/Settings";
import './Root.module.css';
import styles from './Root.module.css';
import CustomComponentsContext from '../../contexts/customComponents.context'
import _ from 'lodash';

const { Header, Sider, Content } = Layout;
const { Text } = Typography;

const GMAP_KEY = process.env.REACT_APP_GMAP_KEY ?? false;

export const Root = (props) => {
  const { isLoading, isLogged } = useAthentication(props.authentication);

  if (!isLoading && !isLogged) {
    return <Redirect to="/login"></Redirect>
  }

  if (isLoading) {
    return null
  }

  if (!isLoading && isLogged) {
    if (GMAP_KEY) {
      return <CustomComponentsContext.Provider value={props.customComponents}>
        <LoadScript libraries={["places"]} googleMapsApiKey={GMAP_KEY}>
          <RootBody {...props}></RootBody>
        </LoadScript>
      </CustomComponentsContext.Provider>
    }

    return <CustomComponentsContext.Provider value={props.customComponents}>
      <RootBody {...props}></RootBody>
    </CustomComponentsContext.Provider>
  }

  return null;
}


const RootBody = (props) => {
  const { recordsActions, entityActions, customComponents, clientSideMenu } = props;

  const history = useHistory();
  const [collapsed, setCollapsed] = React.useState(false)
  const location = useLocation();
  // const scrollbarRef = useRef(null);

  const [basicSettings] = useRecord({
    entity: 'BASICSETTINGS',
    lk: 'default'
  })

  const [sidebarMenu] = useAllRecord({
    entity: 'SIDEBARMENU'
  })


  const openkeysFromLoc = (location) => {
    return collapsed ? '' : location.pathname.split("/")[1]
  }

  let TheMenu = null;
  let treeArray = []
  const fetched = basicSettings.data && sidebarMenu.data;

  if (fetched) {
    // NB: this should be improved, we are making a union from server side menu and client side custom menu (but without going recursive)
    treeArray = entityUtils.convertRecordsToTree(sidebarMenu.data);
    treeArray = _.unionBy(treeArray, clientSideMenu, "id")
    treeArray = entityUtils.nestedTreeSort(treeArray);

    const menuAlgo = (menuArray) => menuArray && menuArray.map(menuAlgoIterm)
    const menuAlgoIterm = (treeElem) => {

      // if enabled for production or development
      if (treeElem && ((process.env.NODE_ENV !== 'development' && !treeElem.dev && treeElem.enabled) || (process.env.NODE_ENV === 'development' && treeElem.enabled))) {
        const CompIcon = Icons[treeElem.menuIcon];
        if (treeElem.menuType === "GROUP") {
          return <SubMenu icon={CompIcon ? <CompIcon /> : undefined} title={treeElem.title && (collapsed ? treeElem.title.charAt(0).toUpperCase() : treeElem.title)} key={treeElem.routePath}>
            {menuAlgo(treeElem['children'])}
          </SubMenu>
        }
        if (treeElem.menuType === "ENTITY" || treeElem.menuType === "CUSTOM_COMPONENT") {
          return <Menu.Item key={treeElem.routePath} icon={CompIcon ? <CompIcon /> : undefined}>
            <NavLink to={treeElem.routePath}>{treeElem.title}</NavLink>
          </Menu.Item>
        }
        if (treeElem.menuType === "LABEL") {
          return <Menu.ItemGroup icon={CompIcon ? <CompIcon /> : undefined} key={treeElem.id}
            title={<Text strong>{treeElem.title}{CompIcon ? <span> <CompIcon /></span> : undefined}</Text>}>
            {menuAlgo(treeElem['children'])}
          </Menu.ItemGroup>
        }
        return null;
      }

    }

    TheMenu = <Menu theme="dark" mode="inline" defaultOpenKeys={[openkeysFromLoc(location)]} selectedKeys={[location.pathname]}
   /* onOpenChange={() => {setTimeout(() => scrollbarRef.current?.updateScroll(), 200)}} */>
      {menuAlgo(treeArray)}
    </Menu>
  }


  const userMenu = <Menu>
    <Menu.Item> <NavLink to={`/settings`}>Settings</NavLink></Menu.Item>
    <Menu.Divider></Menu.Divider>
    <Menu.Item onClick={() => { history.push("/logout") }}>Logout</Menu.Item>
  </Menu>

  const logoNav = fetched ? (basicSettings.data?.logo ? <img className={collapsed ? styles.collapsed : ''} src={basicSettings.data.logo} alt="logo"></img> : <HomeOutlined className={collapsed ? styles.collapsed : ''} />) : null

  const SideBarRouteMap = (array) => array && array.map(a => SideBarRoute(a))
  const SideBarRoute = (record) =>
    [(record && record.enabled && record.menuType !== "LABEL") ?

      <Route exact path={record.routePath} render={() => {
        let routeComponent = null;
        if (record.menuType === "ENTITY" && record.entityConfig?.entity) {
          const namespace = record.entityConfig?.namespace;
          const entity = record.entityConfig?.entity

          const nsRecordActions = recordsActions && recordsActions({ namespace, entity });
          const nsEntityActions = entityActions && entityActions({ namespace, entity })
          routeComponent = <NSEntityView key={record.entityConfig?.namespace + "_" + record.entityConfig?.entity} namespace={namespace} entity={entity} recordsActions={nsRecordActions} entityActions={nsEntityActions}></NSEntityView>
        }
        if (record.menuType === 'CUSTOM_COMPONENT' && record.customComponentConfig?.component) {
          const customComponentKey = record.customComponentConfig?.component
          const customComponentConfig = customComponents[customComponentKey];
          const ActualCC = customComponentConfig.component;
          if (ActualCC)
            routeComponent = <ActualCC /> // TODO pass props
        }
        return <ErrorBoundary key={record.routePath} fallbackRender={({ error }) => (
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong."
          />
        )}>{routeComponent}</ErrorBoundary>
      }} /> : null,
    (record && record.children) ? SideBarRouteMap(record.children) : null]

  const BreadcrumbFn = (route, params, routes, paths) => {
    const noLink = route.record && ["GROUP", "LABEL"].includes(route.record.menuType)
    const last = routes.indexOf(route) === routes.length - 1;
    const CompIcon = route.record && Icons[route.record.menuIcon];
    return last || noLink || !route.path ? (
      <span>{CompIcon ? <span><CompIcon /> </span> : null}{route.breadcrumbName}</span>
    ) : (
      <NavLink to={route.path}>{CompIcon ? <span><CompIcon /> </span> : null}{route.breadcrumbName}</NavLink>
    );
  }
  const BreadcrumbRouteMap = (array, path) => array && array.map(a => BreadcrumbRoute(a, path))
  const BreadcrumbRoute = (record, path) =>
    [(record && record.enabled && ['ENTITY', 'CUSTOM_COMPONENT'].includes(record.menuType)) ? <Route key={path} exact path={record.routePath} render={() => {
      // if (record.menuType === "ENTITY" ) {

      const routes = [{
        path: '/',
        breadcrumbName: <HomeOutlined />
      }]

      for (let p of path) {
        routes.push({
          path: p.routePath,
          breadcrumbName: p.title,
          record: p
        })
      }

      routes.push({
        path: record.routePath,
        breadcrumbName: record.title,
        record: record
      })

      return <Breadcrumb routes={routes} itemRender={BreadcrumbFn} />

      //}
      // return <NSEntityView key={record.entityConfig?.namespace + "_" + record.entityConfig?.entity} namespace={record.entityConfig?.namespace} entity={record.entityConfig?.entity}></NSEntityView>
      //return null;
    }} /> : null,
    (record && record.children) ? BreadcrumbRouteMap(record.children, path.concat(record)) : null]






  return <Layout className={styles.layout}>
    <Sider style={{
      height: '100vh',
      position: 'fixed',
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      zIndex: 100
    }}
      width={basicSettings.data?.sidebarWidth || "220px"}
      collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
      {fetched ? (<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div className={styles.logo}><NavLink to="/">{logoNav}</NavLink></div>
        <div style={{ overflow: 'auto' }}>
          {TheMenu}
        </div>
      </div>) : null}
    </Sider>
    <Layout style={{ marginLeft: collapsed ? '80px' : (basicSettings.data?.sidebarWidth || "220px") }}>
      <Header className={styles.layoutBackground}
        style={{ padding: 0, position: 'sticky', top: 0, width: '100%', zIndex: 99, height: 'auto', borderBottom: '1px solid #f0f2f5' }}>
        <PageHeader
          extra={<Dropdown overlay={userMenu}><Button type="text">
            {AuthService.getCurrentUserAvatar() ? <Avatar src={AuthService.getCurrentUserAvatar()} /> : <Avatar icon={<UserOutlined></UserOutlined>} />}
            {AuthService.getCurrentUser()}</Button>
          </Dropdown>}
        />
      </Header>

      <div style={{
        margin: '16px'
      }}>
        {BreadcrumbRouteMap(treeArray, [])}
      </div>

      <Content
        className={styles.layoutBackground}
        style={{
          margin: '0px 16px 16px 16px',
          minHeight: 280,
        }}
      >

        {!fetched ? (<div style={{ margin: "24px" }}><Skeleton active /></div>) : (
          <Switch>
            <Route exact path="/">
              <BasicHome basicSettings={basicSettings.data} treeMenu={treeArray}></BasicHome>
            </Route>
            <Route exact path="/settings" component={Settings}></Route>
            {SideBarRouteMap(treeArray)}
            <Route path="*"><NotFound></NotFound></Route>
          </Switch>
        )}
      </Content>
    </Layout>
  </Layout>
}