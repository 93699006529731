import { ColumnHeightOutlined, PlusOutlined, SearchOutlined, SettingOutlined, DownOutlined } from '@ant-design/icons';
import * as Components from "antd";
import * as _ from "lodash";
import React, { useEffect, useRef, useState } from 'react';
import { entityDisplayName } from '../common/entityUtils';
import { getEntityTableViewLocale, getEntityViewLocale } from "../common/locale";
import { getRecordLk } from '../common/recordUtils';
import { EntityTableViewProps, EntityViewProp, RecordOperation } from '../common/types';
import { EntityRecordNew, EntityRecordView } from '../entityrecord-view';
import { recordStatusHeader, reloadButton } from "./common";
import { TableViewConverter } from "./tableViewConverter";
import { allCheckedFn, checkedFields, getActiveFields, treeFieldSettingsFn } from "./treeFieldSettings";

enum ENTITY_STATE {
    LIST,
    RECORD,
    NEW_RECORD,
    CLONE_RECORD
}


const EntityTableView = (props: EntityTableViewProps) => {

    // Avoid using controlled value (due to some errors of rendering of antd with selected keys)
    const [size, setSize] = useState(props.defaultSize ?? "middle");
    const tableSizeMenu = <Components.Menu defaultSelectedKeys={[props.defaultSize ?? "middle"]} selectable onSelect={({ key }) => { setSize(key as any); props.onSizeChange && props.onSizeChange(key as any) }}>
        {["small", "middle", "large"].map(st =>
            <Components.Menu.Item key={st}>{st}</Components.Menu.Item>)}
    </Components.Menu>
    const [records, setRecords] = useState(props.records);
    useEffect(() => {
        if (quickFilterRef.current)
            onSearch(quickFilterRef.current)
        else setRecords(props.records)
    }, [props.records])
    const quickFilterRef = useRef(undefined);

    const locale = getEntityTableViewLocale(props.config)

    const displayToolbar = props.displayToolbar !== undefined ? props.displayToolbar : true;
    const quickFilter = props.quickFilter !== undefined ? props.quickFilter : true;

    const onSearch = (value: any) => {
        // TODO props on quickFilter when we have pagination or a lot of remote data ???
        quickFilterRef.current = value;
        if (value && value != "") {
            const filtered = props.records.filter(r => {
                const val = getRecordLk(r, props.schema);
                return val && val.indexOf(value) > -1
            })
            setRecords(filtered)
        } else {
            setRecords(props.records)
        }
    };

    const enabledFields = treeFieldSettingsFn(props.schema.fields, "", props.config);
    const defaultChecked = checkedFields(enabledFields, props.defaultViewColumns)
    const [checked, setChecked] = useState(defaultChecked)
    const activeFields = getActiveFields(enabledFields, checked);
    const defaultAllChecked = allCheckedFn(enabledFields, activeFields)
    const allCheckedCallback = (e: any) => {
        const checked = checkedFields(enabledFields, e.target.checked ? undefined : []);
        setChecked(checked);
        props.onViewColumnsChange && props.onViewColumnsChange(checked);
    }
    const settingsTree = <Components.Tree.DirectoryTree
        checkable
        defaultExpandAll
        selectable={false}
        onCheck={(checked: any) => { setChecked(checked); props.onViewColumnsChange && props.onViewColumnsChange(checked) }}
        showIcon={false}
        checkedKeys={checked}
        showLine={{ showLeafIcon: false }}
        treeData={enabledFields} />

    return <div>
        {displayToolbar && <div className={"gemini-table-toolbar"}>
            <div className={"gemini-table-toolbar-left"}>
                <Components.Space style={{ lineHeight: 0 }}>

                    <Components.Popover arrowPointAtCenter={true} autoAdjustOverflow={false} className={"gemini-table-toolbar-setting-item"} placement="bottomLeft" trigger={['click']}
                        title={<Components.Checkbox checked={defaultAllChecked} onChange={allCheckedCallback}>{locale.tableView.treeFieldSettings.selectAll}</Components.Checkbox>} content={settingsTree}
                    >
                        <SettingOutlined />
                    </Components.Popover>

                    <Components.Dropdown className={"gemini-table-toolbar-settings-item"} trigger={['click']} overlay={tableSizeMenu}
                        placement="bottomCenter" arrow>
                        <ColumnHeightOutlined />
                    </Components.Dropdown>

                    {quickFilter && <Components.Input prefix={<SearchOutlined style={{ opacity: 0.4 }} />} bordered={false} placeholder={locale.quickFilter} onChange={(event: { target: { value: any } }) => onSearch(event.target.value)} />}

                </Components.Space>
            </div>
        </div>}
        <TableViewConverter {...props} activeFields={activeFields} records={records} size={size} ></TableViewConverter>
    </div>
}

const EntityView = (props: EntityViewProp) => {
    const { entityActionsStrategy, entityActions, schema } = props

    const [flowState, setViewState] = React.useState(props.schema.singleRecord ? ENTITY_STATE.RECORD : ENTITY_STATE.LIST);
    const [records, setRecords] = React.useState(props.records);
    const [targetRec, setRecord] = React.useState(props.schema.singleRecord && props.records.length == 1 ? props.records[0] : {});

    // getEntityRecordViewLocale(props.)
    const locale = getEntityViewLocale(props.config?.allViews)

    const innerState: any = useRef({})

    React.useEffect(() => {
        if (props.schema.singleRecord && props.records.length == 1) {
            setRecord(props.records[0])
        }
        setRecords(props.records)
    }, [props.records])

    const onRecordView = (_text: any, record: any, _index: any) => {
        setRecord(record)
        setViewState(ENTITY_STATE.RECORD)
    }


    const onRecordUpdate: RecordOperation = async (context, ok, error) => {

        const viewOk = () => {
            const newRecords = [...records];
            const index = newRecords.findIndex(item => context.lk === getRecordLk(item, props.schema));
            if (index > -1) {
                const item = newRecords[index];
                newRecords.splice(index, 1, {
                    ...item,
                    ...context.record,
                });
                setRecords(newRecords);
            }
            ok();
        }

        if (props.onRecordUpdate) {
            await props.onRecordUpdate(context, viewOk, error)
        }

    }

    const onRecordCreate: RecordOperation = async (context, ok, error) => {


        const createOk = () => {
            ok()
            setRecord(context.record)
            setViewState(ENTITY_STATE.RECORD)

            // TODO ADD TO LIST (sorted ??)
            setRecords([...records, context.record]);
        }

        if (props.onRecordCreate) {
            await props.onRecordCreate(context, createOk, error);
        }
    }

    const onRecordDelete: RecordOperation = async (context, ok, error) => {

        const deleteOk = () => {
            ok()
            const newRecords = [...records];
            const index = newRecords.findIndex(item => getRecordLk(context.record, props.schema) === getRecordLk(item, props.schema));
            if (index > -1) {
                newRecords.splice(index, 1)
                setRecords(newRecords);
            }
            setViewState(ENTITY_STATE.LIST)
        }

        if (props.onRecordDelete) {
            await props.onRecordDelete(context, deleteOk, error);
        }
    };

    const onRecordClone: RecordOperation = async (context, ok) => {

        if (!props.schema.singleRecord) {

            innerState.current = {
                record: JSON.parse(JSON.stringify(context.record))
            }
            setViewState(ENTITY_STATE.CLONE_RECORD)
            ok();
        }

        // TODO Tree clone
    }

    const onBackClone = () => {
        setViewState(ENTITY_STATE.RECORD)
    }


    const onBack = props.schema.singleRecord ? undefined : () => {
        setViewState(props.schema.singleRecord ? ENTITY_STATE.RECORD : ENTITY_STATE.LIST)
    }

    const onNew = () => {
        setViewState(ENTITY_STATE.NEW_RECORD)
    }

    const listStyle = () => {
        return {
            display: flowState === ENTITY_STATE.LIST ? 'block' : 'none'
        }
    }

    let inActions: any[] = [];
    if (!schema.singleRecord) {
        inActions = entityActions ? inActions.concat(entityActions(props)) : inActions
    }


    let extra = [
        inActions.length > 0 ? <Components.Dropdown key="actions" overlay={<Components.Menu>{inActions}</Components.Menu>}>
            <Components.Button>
                {locale.recordOperations.actions.button}<DownOutlined />
            </Components.Button>
        </Components.Dropdown> : null,

        <Components.Button key="edit" type="primary" onClick={onNew}><PlusOutlined />{locale.recordOperations.create.opeButton}</Components.Button>]
    const title = entityDisplayName(props.schema)

    let subtitle = <div>
        {recordStatusHeader(props.recordsStatus, props.visibleStatus, props.oldStatusConfig, locale)}
        {reloadButton(props.recordsStatus, props.onReload)}
    </div>
    const pageHeader = <Components.PageHeader key="headerTitle" title={title} subTitle={subtitle} extra={extra}></Components.PageHeader>

    function ignoreArray(objValue: any, srcValue: any) {
        if (_.isArray(objValue)) {
            return srcValue;
        }
    }

    const tableViewConfig = props.config && _.mergeWith((_.cloneDeep(props.config.allViews)) || {}, props.config.tableView, ignoreArray);
    const recordViewConfig = props.config && _.mergeWith((_.cloneDeep(props.config.allViews)) || {}, props.config.recordView, ignoreArray);

    return <div>

        {flowState == ENTITY_STATE.LIST && pageHeader}
        {props.schema.singleRecord ? null : <div key="entityList" className="padding-o-lg" style={listStyle()}>
            <EntityTableView {...props} config={tableViewConfig} records={records}
                defaultPagination={props.defaultTablePagination} defaultViewColumns={props.defaultTableViewColumns}
                pagination={props.tablePagination} displayToolbar={props.displayTableToolbar} defaultSize={props.defaultTableSize}
                onRow={props.onTableRow} onRecordView={onRecordView} onChange={props.onTableChange}
                onSizeChange={props.onTableSizeChange} onViewColumnsChange={props.onTableViewColumnsChange}
            ></EntityTableView>
        </div>}

        {flowState == ENTITY_STATE.NEW_RECORD && <EntityRecordNew {...props} config={recordViewConfig} onRecordCreate={onRecordCreate} onBack={onBack}></EntityRecordNew>}
        {flowState == ENTITY_STATE.RECORD && <EntityRecordView {...props} recordsStatus={props.recordsStatus} config={recordViewConfig} record={targetRec}
            actions={props.recordActions} actionsStrategy={props.recordActionsStrategy}
            onRecordClone={onRecordClone} onRecordUpdate={onRecordUpdate} onRecordDelete={onRecordDelete} onBack={onBack}></EntityRecordView>}
        {flowState == ENTITY_STATE.CLONE_RECORD && <EntityRecordNew {...props} config={recordViewConfig} record={innerState.current.record} onRecordCreate={onRecordCreate} onBack={onBackClone}></EntityRecordNew>}

    </div>
}

export { EntityTableView, EntityView };
