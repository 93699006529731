import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { UploadFile } from "antd/lib/upload/interface";
import { FastField, FieldProps } from "formik";
import React, { useState } from "react";
import { EditFieldSpec, FieldSpec, ViewFieldSpec } from "./types";


const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_f) => { resolve(reader.result) };
    reader.onerror = error => reject(error);
});

const B64Modal = ({ fp, fieldName, field }: EditFieldSpec & { fp: FieldProps }) => {
    const [modal, setModal] = useState(false)

    let fl = [] as UploadFile[]
    if (typeof fp.field.value === "string" && fp.field.value.length > 0) {  // TODO check b64 ?
        fl = [{
            uid: '-1',
            status: 'done',
            url: fp.field.value
        }] as UploadFile[]
    }

    return (<>
        <ImgCrop rotate quality={1}>
            <Upload
                listType="picture-card"
                fileList={fl}
                onRemove={() => fp.form.setFieldValue(field.name, null)}
                beforeUpload={file => {
                    fp.form.setFieldTouched(fieldName, true, false);
                    toBase64(file).then(function (b64) {
                        fp.form.setFieldValue(fieldName, b64)
                    })
                    return false;
                }}

                onPreview={() => {
                    setModal(true)
                }}
            >
                {fl.length === 0 ?
                    <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Load Image</div>
                    </div> : null
                }
            </Upload>
        </ImgCrop>
        <Modal
            visible={modal}
            title={"Preview"}
            footer={null}
            onCancel={() => { setModal(false) }}
        >
            <img alt="example" style={{ width: '100%' }} src={fp.field.value} />
        </Modal>
    </>
    )
}


export const EditFieldComponent = (props: EditFieldSpec) => {
    const { fieldName } = props
    return <FastField name={fieldName} children={(fp: FieldProps) => <B64Modal {...props} fp={fp} />} />
}


export const ViewFieldComponent = (props: ViewFieldSpec) => {
    const { value } = props

    const fl = (value ? [{
        uid: '-1',
        status: 'done',
        url: value
    }] : []) as UploadFile[]
    return (() => {
        const [modal, setModal] = useState(false)

        return <>
            <Upload
                listType="picture-card"
                fileList={fl}
                beforeUpload={_ => {
                    return false;
                }}
                onPreview={() => {
                    setModal(true)
                }}
                showUploadList={{ showPreviewIcon: true, showRemoveIcon: false, showDownloadIcon: false }}

            >
            </Upload>
            <Modal
                visible={modal}
                title={"Preview"}
                footer={null}
                onCancel={() => { setModal(false) }}
            >
                <img alt="preview" style={{ width: '100%' }} src={value} />
            </Modal>
        </>
    })()
}


export default { EditFieldComponent, ViewFieldComponent } as FieldSpec