import { Button, Col, Collapse, Input, Row } from "antd";
import { ArrayHelpers, FastField, FieldArray } from "formik";
import React from "react";
import { recordConverterFieldOverObject } from "../recordDetailsConverters";
import { RecordConverterFieldOverObject } from "../recordEditConverter";
import { EditFieldSpec, FieldSpec, ViewFieldSpec } from "./types";
//import { recordConverterFieldOverObject } from "../recordDetailsConverters";

export const EditFieldComponent = (props: EditFieldSpec) => {
    const { fieldName, field, value, innerState, path, config, formikContext } = props

    const fields = field.dict!.fields
    const fieldArray = <FieldArray name={fieldName}>
        {({ remove, push }: ArrayHelpers) => {
            let panelItems: any[] = [];
            value && value.map((val: any, key: number) => {
                const innerPath = (path === "" ? field.name : path + "." + field.name) + "." + key;
                const DangerButton = () => {
                    return <Button danger key="delete" onClick={(event) => {
                        remove(key);
                        event.stopPropagation();
                    }}>Delete</Button>
                }

                let header = <Row gutter={8} style={{flex: 1}}>
                    <Col flex="auto"><FastField name={innerPath + ".___key"} as={Input} onClick={(event: any) => { event.stopPropagation() }}></FastField></Col>
                    <Col>
                        <DangerButton></DangerButton>
                    </Col>
                </Row>

                panelItems.push(<Collapse.Panel key={key} header={header}>
                    <RecordConverterFieldOverObject recordAtLevel={val} fields={fields} formikContext={formikContext} innerState={innerState} config={config} path={innerPath}></RecordConverterFieldOverObject>
                </Collapse.Panel>)

            })

            var res = [];
            if (panelItems.length > 0) {
                const collapse = <Collapse key={'collapse'} ghost>{panelItems}</Collapse>
                res.push(collapse)
            }

            const addButton = <Button key="new" block onClick={(event) => {
                event.stopPropagation();
                push({})
            }}>Add</Button>
            return <div className="dictionary-field">{res}{addButton}</div>;
        }}
    </FieldArray>
    return fieldArray;
}

export const ViewFieldComponent = (props: ViewFieldSpec) => {
    const { value, field, path, innerState, config } = props

    if (value != null && typeof value === 'object') {
        const fields = field.dict!.fields
        let panelItems = [];

        for (let key in value) {
            const innerPath = (path === "" ? field.name : path + "." + field.name) + "." + key;
            let objVal = value[key];
            let header = <b>{key}</b>;
            panelItems.push(<Collapse.Panel key={key} header={header}>{recordConverterFieldOverObject(objVal, fields, innerState, config, innerPath)}</Collapse.Panel>)
        }

        var res = [];
        if (panelItems.length > 0) {
            const collapse = <Collapse key={'collapse'} ghost>{panelItems}</Collapse>
            res.push(collapse)
        }
        return res;
    }

    return "";
}
export default { EditFieldComponent, ViewFieldComponent } as FieldSpec

