import { Field, FieldType, GeminiSchema } from "./schema";
import _ from "lodash";
import { EntityRecordViewConfig } from "./types";
import { activeField, getRecordLk } from "./recordUtils";

function checkFields(valuesLevel: Object, valuesAll: Object, fields: Field[], config?: EntityRecordViewConfig, path: string = "") {
    const errors = {}


    for (const field of fields) {

        const fieldName = path == "" ? field.name : path + "." + field.name;
        const fieldConfig = _.get(config?.fields, fieldName);

        if (!activeField(valuesAll, fieldName, config))
            continue

        // if it is a single value field
        if ([FieldType.INTEGER, FieldType.BOOL, FieldType.STRING, FieldType.ENUM, FieldType.SELECT, FieldType.B64_IMAGE].indexOf(field.type) >= 0) {

            // first check is required
            if (field.required) {
                if (!valuesLevel || !valuesLevel.hasOwnProperty(field.name) || valuesLevel[field.name] === null || valuesLevel[field.name] === undefined
                    || valuesLevel[field.name] === "" || valuesLevel[field.name] === [])
                    errors[field.name] = "required"
            }

            // then specific ones that overrides required
            if (valuesLevel && valuesLevel.hasOwnProperty(field.name) && valuesLevel[field.name] !== null && valuesLevel[field.name] !== undefined) {
                if (field.type == FieldType.STRING && valuesLevel[field.name] != "" && fieldConfig) {

                    if (fieldConfig.displayType === "JSON") {
                        try {
                            JSON.parse(valuesLevel[field.name]);
                        } catch (e) {
                            errors[field.name] = "invalid_json"
                        }
                    }

                    if (fieldConfig.displayType === "CODE") {
                        if (fieldConfig.codeConfig?.mode === "javascript") {
                            try {
                                new Function(valuesLevel[field.name]);
                            } catch (e) {
                                errors[field.name] = "invalid_javascript"
                            }
                        }
                    }
                }

            }
        }

        if (field.type === FieldType.ARRAY) {
            const arrayType = field.array!.type;
            if (field.required) {
                if (!valuesLevel || !valuesLevel.hasOwnProperty(field.name) || !Array.isArray(valuesLevel[field.name]) || valuesLevel[field.name].length === 0)
                    errors[field.name] = "required"
                else {
                    if (arrayType === FieldType.OBJECT) {
                        var innerErr = [];
                        for (let i = 0; i < valuesLevel[field.name].length; i++) {
                            const innerReq = checkFields(valuesLevel[field.name][i], valuesAll, field.array!.object!.fields, config, fieldName + "." + i);
                            if ((Object.keys(innerReq).length > 0))
                                innerErr[i] = innerReq;
                        }
                        if (innerErr.length > 0) {
                            errors[field.name] = innerErr;
                        }
                    }

                }
            }

            if (valuesLevel && arrayType === FieldType.STRING && valuesLevel.hasOwnProperty(field.name) && Array.isArray(valuesLevel[field.name])) {
                // check for some undefined
                var innerErr = [];
                for (let i = 0; i < valuesLevel[field.name].length; i++) {
                    if (valuesLevel[field.name][i] === "")
                        innerErr[i] = "required"
                }
                if (innerErr.length > 0) {
                    errors[field.name] = innerErr;
                }
            }
        }

        // otherwise lets go inside the json tree
        if (field.type === FieldType.OBJECT) {
            if (field.required && !valuesLevel) {
                errors[field.name] = "required"
            }

            if (!field.required && valuesLevel) {
                const innerReq = checkFields(valuesLevel[field.name], valuesAll, field.object!.fields, config, fieldName);
                if ((Object.keys(innerReq).length > 0))
                    errors[field.name] = innerReq;
            }
        }

    }


    return errors;
}



const topValidation = (schema: GeminiSchema, config?: EntityRecordViewConfig, cloneRec?: any) => {

    return (values: Object) => {
        let errors = {}

        if (!_.isEmpty(cloneRec) && getRecordLk(values, schema) === getRecordLk(cloneRec, schema)) {
            for (const lkf of schema.lk) {
                // TODO nested LK fields
                errors[lkf] = "duplicateLk"
            }
        }

        const reqErrors = checkFields(values, values, schema.fields, config)
        errors = _.merge(reqErrors, errors)

        return errors;
    }
}

export { topValidation }